.CusHomeP{
    color: black !important;
    font-size: 16px;
}
.CusHomePGray{
    color: #9B9B9B !important;
    font-size: 16px;
}
.HomeR3R1{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.HomeR3R1 p {
    color: #020202 !important;
    font-size: 16px !important;
    /* padding: 20px; */
}
.Congratulations{
    background-color: #79e75a4a;
    font-weight: 900;
}