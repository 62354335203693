.cusS1SaveColP8{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 27%;
}
.cusS1SaveColP8 .btn-outline-light{
    color: black !important;
    border-color: #c3c3c3;
    border-radius: 8px;
    height: calc(1.5em + .75rem + 10px) !important;
}
.cusS1SaveColP8 .btn-dark{
    border-radius: 8px;
    height: calc(1.5em + .75rem + 13px) !important;
}
.botCusDraftCol{
    display: flex;
}
.botCusDraftCol .btn-outline-light{
    border-color: transparent;
    color: #D3A281 !important;
}
.botCusDraftCol svg {
    margin-bottom: 5px;
    margin-inline-end: 5px;
}
