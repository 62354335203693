.editandtrash{
    display:none;
}
.editandtrash svg{
    transform:rotate(90deg);
}
.img_agentCus{
    position:relative;
}
svg.star_ofdcpluslist{
    position:absolute;
    top:-5px;
    border:1px solid #959177;
    border-radius:50%;
    background-color:#fff;
    padding:2px;
}
svg.star_ofdcpluslist:lang(en){
   left:0;
}
svg.star_ofdcpluslist:lang(fa){
   right:0;
}
@media only screen and (max-width: 991.98px){
    #myServiceBtn{
        position:fixed;
        z-index:999;
        bottom:5%;
        width:50px;
        height:50px;
        min-width:50px !important;
        border-radius:50% !important;
        font-size:0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #myServiceBtn::before{
        content:'+';
        font-size:28px;
        color:#fff;
    }
    #myServiceBtn:lang(en){
        right:5%;
    }
    #myServiceBtn:lang(fa){
        left:5%;
    }
    .mynewRowCusStep111,.PenAndTrash{
        display:none !important;
    }
    
    .CusSituationGray{
        padding:7px;
    }
    .ItemBoxExtend>div>*{
        display:inline-block;
    }
    .editandtrash{
        display:inline-block;
    }
    
}