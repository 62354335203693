.mainContent{
    padding:0;
}
.HomeMainRow{
    display:flex;
    flex-direction:column;
    padding:0;
}
.HomeRow1{
    display:flex;
    justify-content:space-around;
    padding:0;
}
.box_summary{
    padding:0 7px;
}
.box_summary .HomeLightBoxRowOne{
    height:150px;
}
.HomeLightBoxRowOne{
    display:flex;
    background-color:white;
    padding:15px;
    justify-content:space-around;
    border-radius:18px;
    transition:all .2s ease-in-out;
}
.HomeLightBoxRowOne:hover{
    transform:scale(1.05);
}
.HomeRow2{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    padding:10px 0;
}
.HomeRow2:first-child{
    padding-top:0 !important;
}
.total_HomeLightBoxRowTwo,.total_summary{
    display:flex;
    padding:0;
    flex-wrap: wrap;
}
.itemBox{
    display:flex;
    background-color:rgb(255, 255, 255);
    border-radius:10px;
    align-items:center;
    padding:10px;
    margin-bottom:10px;
    height:60px;
    transition:all 0s ease-in-out;
}
.itemBox:hover{
    transform:scale(1.009);
    z-index:1;
}
.itemBox:hover .ChoosBox{
    z-index:9999999;
}
.itemBox p{
    color:#313131 !important;
    margin-bottom:0rem;
    font-size:12px !important;
}
.itemBox button{
    font-size:12px !important;
}
.itemBox .CusPic{
    justify-content:flex-start;
}
.CusPic img{
    height:50px;
    width:50px;
}
.collist_item{
    padding:0 5px;
}
.total_HomeLightBoxRowTwo .itemBox.deactiveitem:hover{
    background-color:#FBF8F8;
}
.total_HomeLightBoxRowTwo .itemBox.activeitem:hover{
    background-color:#F8FBF9;
}
.lasted_laworage .itemBox:hover,.home_agentandlawyer .item_tariff:hover{
    background-color:#F8F8FB;
}
.head_rowserv,.head_rowserv .agentCusRow,.head_rowserv:hover{
    background-color:#f9f3ef !important;
    padding:0 !important;
}
.head_rowserv .agentCusRow{
    padding:0 5px !important;
}
.btn_de_active,.coun_clients{
   padding:0;
}
.btn_de_active button,.coun_clients>div.HomeGrayCilents{
    width:90px;
    height:33px;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
}
.btn_de_active span{
    color:#fff
}
.total_summary{
    padding:15px 0;
}
.box_HomeLightBoxRowTwo{
    padding:7px 7px;
}
.HomeLightBoxRowTwo{
    background-color:white;
    padding:30px;
    border-radius:18px;
    height:100%;
    overflow: hidden;
}
.home_agentandlawyer .HomeRow2{
    padding-bottom:0;
}
.home_agentandlawyer .HomeLightBoxRowTwo{
    padding:30px 10px;
}
.home_agentandlawyer .HomeLightBoxRowTwo .mainRowCusHomeR2{
    height:250px;
    overflow-y:scroll;
    overflow-x:hidden;
}
.home_agentandlawyer .box_HomeLightBoxRowTwo .HomeR2R1{
    padding:0 10px;
}
.homeCell2 h4{
    font-size:20px !important;
    color:#A2A1BC !important;
}
.homeCell2 p{
    font-size:32px !important;
    color:black !important;
}
.homeCell2{
    display:flex;
    flex-direction:column;
    align-content:center;
    align-items:center;
}
.homeCell1{
    display:flex;
    flex-direction:column;
    justify-content:center;
}
/* .homeCell3 p{
    color:#4A9D77 !important;
}
.homeCell32 p{
    color:#E95060 !important;
}
.homeCell33 p{
    color:#5654b5 !important;
} */
.HomeR2R1 p{
    font-size:18px !important;
    color:black !important;
}
.HomeR2R1{
    display:flex;
    justify-content:space-between;
}
.HomeR2R1.outer_box{
    display:none;
}
.mainRowCusHomeR2{
    overflow-y:auto;
    scrollbar-color:transparent;
    box-shadow:hsl(0deg 0% 80% / 0%) 0 0 16px;
    background-color:rgba(255, 255, 255, 0);
    border-radius:20px;
    padding:7px;
    margin-inline-start:0%;
    text-align:center;
}
.img_CusHomeR2{
    width:100px;
    height:100px;
    border-radius:50%;
    border:3px solid #d6a887;
    margin:0 auto 8px auto;
}
.img_CusHomeR2 img{
    max-height:100px;
}
.AddUserHome{
    cursor:pointer;
}
.ChoosBox2{
    padding:10px;
}
.ChoosBox2 p{
    color:black !important;
    text-align:center;
}
.HomeGrayCilents{
    background-color:#ced1cf;
    border-color:transparent;
    color:#4A9D77 !important;
    border-radius:8px;
}
.HomeCol3P1{
    color:#333333 !important;
    font-size:14px !important;
}
.HomeCol3P2{
    color:#ff5b5b !important;
    font-size:14px !important;
}
.HomeCol3Row2{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
}
.gZZBOx,.rec-pagination{
    justify-content: center;
}
.izuVIe,.rec-item-wrapper{
    height:100%;
}
.HomeCol3Row2_2{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    padding:0;
}
.HomeCol3Row2_2 *{
    margin-bottom:5px;
}
.HomeCol3Row2_2:first-child{
    margin-bottom:15px;
}
.HomeCol3Row2_2 p{
    margin:0;
}
.svg_HomeLightBox{
    height:45px;
    display:flex;
    align-items:center;
    justify-content: center;
}
.Star{
    cursor: pointer;
}
.qoLPS:lang(fa){
    direction:rtl;
}
.fPDpuU,.qoLPS,.rec-slider-container,.rec-carousel-wrapper{
    padding:0;
    margin:0 !important;
}
.eaptOe,.gpDNSX,.rec-dot{
    background-color: #d7d7d7 !important;
    box-shadow: none !important;
}
.lcUbOd,.bBjxWM,.rec-dot_active{
    background-color: #D3A281 !important;
    font-size: 20px;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: none !important;
}
.home_agentandlawyer .item_tariff{
    box-shadow: none !important;
    border:1px solid #F8F8FB;
}
@media only screen and (max-width:1300.98px) and (min-width:992px){
    .home_dashpan>.total_HomeLightBoxRowTwo{
        width:75%;
        max-width:unset;
    }
    .home_dashpan>.box_HomeLightBoxRowTwo{
        width:25%;
        max-width:unset;
    }
    .home_dashpan .total_HomeLightBoxRowTwo .box_HomeLightBoxRowTwo{
        width:100%;
        max-width:unset;
    }
}
@media only screen and (max-width:991.98px){
    .HomeCol3Row2{
        flex-direction:row;
        flex-wrap:wrap;
        justify-content:space-evenly;
        align-items:flex-start;
    }
    .HomeCol3Row2_2:first-child{
        margin:0;
    }
    .total_HomeLightBoxRowTwo,.total_summary{
        overflow-x:scroll;
        padding-bottom:25px;
        -ms-overflow-style:none; /* for Internet Explorer, Edge */
        scrollbar-width:none;
        padding:0 7px 0;
        margin-bottom:20px;
    }
    .total_summary{
        padding:10px;
        margin:0;
    }
    .box_HomeLightBoxRowTwo,.total_summary .box_summary{
        margin:7px 0;
    }
    .HomeRow2:first-child>.box_HomeLightBoxRowTwo{
        margin-top:25px;
    }
    .total_HomeLightBoxRowTwo::-webkit-scrollbar{
        display:none; /* for Chrome, Safari, and Opera */
    }
    .HomeR2R1.outer_box{
        display:block;
    }
    .sc-jRQBWg .HomeLightBoxRowTwo{
        /* height:calc(100% - (27px + 1rem)); */
        height:calc(100%);
    }
    .HomeLightBoxRowTwo>.HomeR2R1{
        display:none;
    }
    .home_agentandlawyer .HomeLightBoxRowTwo>.HomeR2R1{
        display:flex;
        align-items:center;
    }
    .home_agentandlawyer .HomeLightBoxRowTwo>.HomeR2R1 svg{
        width:32px;
    }
    .home_agentandlawyer .HomeLightBoxRowTwo>.HomeR2R1 p{
        margin-bottom:0;
    }
}
@media only screen and (max-width:767.98px){
    .HomeRow2{
        padding:10px 0;
    }
    .HomeMainRow{
        flex-direction:column-reverse;
    }
    .home_agentandlawyer.HomeMainRow{
        flex-direction:column;
    }
    .box_summary,.total_summary,.box_HomeLightBoxRowTwo,.total_HomeLightBoxRowTwo{
        padding:0;
    }
    .HomeLightBoxRowOne:hover{
        transform:scale(1);
    }
}
@media only screen and (max-width:600.98px){
    .btn_de_active button{
        width:60px;
    }
    .home_agentandlawyer .HomeLightBoxRowTwo .mainRowCusHomeR2,.itemBox{
        padding:7px 5px;
    }
    .itemBox:hover{
        transform:scale(1);
    }
}
@media only screen and (max-width:460.98px){
    .HomeCol3Row2{
        flex-direction:column;
    }
    .HomeCol3Row2_2{
        width:100%;
        max-width:unset;
        margin:10px auto 10px auto !important;
    }
}
@media only screen and (max-width:400.98px){
    .homeCell2 h4{
        font-size:17px !important;
    }
    .homeCell2 p{
        font-size:20px !important;
    }
    
}
 