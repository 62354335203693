.box_message{
    background-color:#fff;
    border-radius:20px;
    padding:20px;
}
#SelectYourUser option{
    color: #000 !important;
}
.box_message .box_HomeLightBoxRowTwo{
    padding:15px;
}
.box_message .HomeLightBoxRowTwo{
    background-color:transparent;
    border-radius:0;
    padding:15px;
    position:relative;
}
.archive_mesages .HomeLightBoxRowTwo,.form_mesages .HomeLightBoxRowTwo{
    max-height:500px;
    overflow-y:scroll;
    border-radius:20px;
    border:1px solid #DFDFDF;
    padding:15px;
} 
.form_mesages .mainRowCusHomeR2,.form_mesages .inputAndlabel{
   padding:0;
}
.form_mesages .mainRowCusHomeR2{
    border-radius:0;
    overflow:visible;
}
.MessageTextArea{
    height:calc(11.5em + .75rem + 56px) !important;
}
.HomeR2R2 p{
    color:black !important;
}
.HomeR2R1Msg{
    display:flex;
    justify-content:space-between;
}
.HomeR2R1Msg p{
    font-size:18px !important;
    color:black !important;
}
.HomeR2R1Msg.outer_box{
    display:none;
}
.AddUserHome{
    cursor:pointer;
}
.ChoosBox2{
    padding:10px;
}
.ChoosBox2 p{
    color:black !important;
    text-align:center;
}
.form_mesages .inputAndlabel{
    margin:8px 0;
}
.form_mesages .inputAndlabel:last-child{
    margin:0;
}
.form_mesages .inputAndlabel textarea{
    height:200px;
}
.form_mesages .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color:black !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    color:black !important;
}
.form_mesages .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    height:48px !important;
    border-radius:8px !important;
}
.form_mesages .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    height:48px !important;
    padding:10px !important;
    display:flex;
    align-items:center; 
    padding:0 10px !important;
}
#MymsgBoxMsg{
    display:none;
}
.sendmessageBtn{
    display:flex;
    justify-content:flex-end;
}
.sendmessageBtn button{
    width:120px;
    border-radius:5px;
    height:40px;
}
.itemBoxMsg{
    display:flex;
    flex-wrap: wrap;
    background-color:rgb(255, 255, 255);
    border-bottom:1px solid #dfdfdf;
    padding:15px 0;
    align-items:center;
    transition:all 0s ease-in-out;
    cursor:pointer;
}
.itemBoxMsg2{
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(247 217 202 / 37%);
    border-radius: 16px;
    padding: 20px 10px;
    align-items: center;
    transition: all 0s ease-in-out;
    cursor: pointer;
}
.itemBoxMsg:last-child{
    border-bottom:0;
}
.msgBodyInList{
    height:25px;
    overflow:hidden;
    width:100%;
    margin-top:7px;
}
.msgBodyInList p{
    text-overflow:ellipsis;
	white-space:nowrap;
	overflow:hidden;
}
.msgRD2M1 p:lang(en){
    color:#333333 !important;
    text-align:left;
    margin:0;
}
.msgRD2M1 p:lang(fa){
    color:#333333 !important;
    text-align:right;
    margin:0;
}
.msgRD2M1 .timesent,.msgRD2M1 .usernamesent{
    display:flex;
    align-items:center;
    height:22px;
}
.msgRD2M1 .timesent p,.msgRD2M1 p.msgBodyInList{
    color:#919191 !important;
    font-size:12px !important;
}
.SenderNameStyle{
    font-size:16px !important;
}
.msgRD2M1{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    padding:0;
    align-items:center;
}
.CusPicMsg{
    padding:0;
    padding-inline-end:10px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:77px;
    height:77px;
    border-radius:50%;
    overflow:hidden;
}
.CusPicMsg img{
    border-radius:50%;
    max-height:77px;
    max-width:100%;
}
.msgPic{
    max-height:77px;
    width:100%;
}
.SenderNameAndMsg{
    padding:0;
}
.MessageBody{
    padding:5px 10px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    position:sticky;
    top:0;
    left:0;
    right:0;
    z-index:99;
    background-color:#fff;
}
.MessageBody>svg:lang(fa){
    transform:rotate(180deg);
}
.MessageBodyMainMe,.MessageBodyMainYou{
    display:flex;
    background-color:#D3A281;
    margin-top:10px;
    padding:20px;
    position:relative;
}
.MessageBodyMainMe:lang(en)::before,.MessageBodyMainYou:lang(fa)::before{
    content:"";
    border-left:0 solid transparent;
    border-top:7px solid #d3a281;
    border-right:7px solid transparent;
    border-bottom:7px solid transparent;
    position:absolute;
    top:0;
    right:-7px;
    border-radius:0 4px 0 0;
}
.MessageBodyMainYou:lang(fa)::before{
    border-top:7px solid #F6F6F6;
    border-radius:0 4px 0 0;
}
.MessageBodyMainMe:lang(fa)::before,.MessageBodyMainYou:lang(en)::before{
    content:"";
    border-left:7px solid transparent;
    border-top:7px solid #d3a281;
    border-right:0 solid transparent;
    border-bottom:7px solid transparent;
    position:absolute;
    top:0;
    left:-7px;
    border-radius:4px 0 0 0;
}
.MessageBodyMainYou:lang(en)::before{
    border-top:7px solid #F6F6F6;
    border-radius:4px 0 0 0;
}
.MessageBodyMainYou{
    background-color:#F6F6F6;
}
.MessageBodyMainMe:lang(en),.MessageBodyMainYou:lang(fa){
    border-radius:8px 0 8px 8px;
}
.MessageBodyMainMe:lang(fa),.MessageBodyMainYou:lang(en){
    border-radius:0 8px 8px 8px;
}
.MessageBodyMainMe p,.MessageBodyMainYou p{
    color:black !important;
    margin-bottom:0px !important;
}
.MessageBodyMainMe a,.MessageBodyMainYou a{
    color:#0000 !important;
    font-size:15px !important;
}
.dirMainMsgMe:lang(en){
    direction:rtl;
    padding-bottom:20px;
    padding:0 8px;
    justify-content:flex-end;
}
.dirMainMsgMe:lang(fa){
    direction:ltr;
    padding-bottom:20px;
    padding:0 8px;
    justify-content:flex-start;
}
.dirMainMsgYou:lang(en){
    direction:ltr; 
    padding-bottom:20px;
    padding:0 8px;
    justify-content:flex-end;
}
.dirMainMsgYou:lang(fa){
    direction:rtl; 
    padding-bottom:20px;
    padding:0 8px;
    justify-content:flex-start;
}
.mySelectedMsg{
    display:none;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
}
.mySelectedMsg .MuiPaper-root{
    position:sticky;
    bottom:-15px;
    left:0;
    right:0;
    box-shadow:none !important;
}
#msgList{
    display:flex;
    flex-direction:column-reverse;
}
#msgList>div{
    margin:0 0 8px 0;
}
#msgList>div:last-child{
    margin:0;
}
.BackToMsgList{
    position:absolute;
    top:calc(50% - 9px);
    color:#333333;
    cursor:pointer;
}
.BackToMsgList:lang(en){
   left:-10px;
}
.BackToMsgList:lang(fa){
    right:-10px;
}
.archive_mesages .MuiButtonBase-root{
    background-color:rgb(211, 162, 129,.8);
}
.archive_mesages .MuiButtonBase-root:hover{
   background-color:rgb(211, 162, 129,1);
}
.archive_mesages .MuiButtonBase-root{
    width:30px;
    height:30px;
}
.archive_mesages .MuiButtonBase-root svg{
    width:17px;
    height:17px; 
}
.archive_mesages .MuiButtonBase-root:last-child svg:lang(fa){
   transform:rotate(180deg);
}
.archive_mesages .css-piqts5-MuiInputBase-root:lang(fa){
    margin:0 !important;
    margin-inline-start:8px !important;
}
.MsgMargin{
    margin-top:15px;
}
.TelesinessSupport{
    background: #fbeae1 !important;
    border-radius: 8px !important;
}
#Subject{
    display: none;
}
@media only screen and (min-width:1900.98px){
    .archive_mesages .HomeLightBoxRowTwo,.form_mesages .HomeLightBoxRowTwo{
        max-height: 800px;
        min-height:800px;
    }
}
@media only screen and (min-width:1439.98px){
    .archive_mesages .HomeLightBoxRowTwo,.form_mesages .HomeLightBoxRowTwo{
        max-height: 700px;
        min-height:700px;
    }
}
@media only screen and (max-width:1199.98px) and (min-width:992px){
    .form_mesages,.archive_mesages{
        width:100% !important;
        max-width:unset;
    }
}
@media only screen and (max-width:991.98px){
  .box_message{
      height:auto;
      overflow:unset;
      flex-wrap:wrap;
  }
  .box_message .total_HomeLightBoxRowTwo .box_HomeLightBoxRowTwo{
      margin:0;
  }
  .archive_mesages .HomeLightBoxRowTwo,.form_mesages .HomeLightBoxRowTwo{
      max-height:unset;
      overflow:unset;
  }
 
}
@media only screen and (max-width:767.98px){
    .box_message{
        padding:0;
    }
    .MessageBodyMainMe, .MessageBodyMainYou{
        padding:10px;
    }
}
@media only screen and (max-width:400.98px){
    .archive_mesages .HomeLightBoxRowTwo{
        padding:8px;
    }
}