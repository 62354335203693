.rowTwoColOnePriceCusS111{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin:0px !important;
    padding-inline-start:14px;
    font-size:16px !important;
    justify-content:flex-end;
    padding:0px;
}
.rowTwoColOnePriceCusS111 .btn{
    border-radius:8px !important;
    min-width:165px;
}
.input-placeholderPriceTwo111 {
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:flex-end;
    align-items:center;
}
.input-placeholderPriceTwo1112 {
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:flex-start;
    align-items:center;
}
.mynewRowCusStep111 p{
    color:#313131 !important;
    padding:0;
    padding-inline-start:10px;
    margin-bottom:0;
}
.mynewRowCusStep111 {
    display:flex;
    justify-content:flex-start;
    flex-direction:row;
    margin-top:20px;
}
.CusSituationGray{
    display:inline !important;
    background-color:#E1E1EF;
    border-radius:8px;
    padding:10px;
    text-align:center;
}
.MyPaddingAlittleMiuns{
    margin-inline-start:-30px;
}
.item_request{
    display:flex;
    box-shadow:hsl(0deg 0% 80% / 22%) 0 0 16px;
    background-color:rgb(255, 255, 255);
    border-radius:16px;
    flex-wrap:wrap;
    align-items:center;
    padding:0;
    margin-bottom:10px; 
    transition:all 0s ease-in-out;
    min-height:70px;
}
.mainRowProPrice2>div:nth-child(even) .item_request{
    background-color:#E8E8E8 !important;
}
.item_request p{
    color:#313131 !important;
    margin-bottom:0rem;
    font-size:12px !important;
}
.item_request .agentCusRow{
    flex-wrap:wrap;
    padding:15px 10px;
}
.item_request .agentCusRow>div{
    padding:0 5px;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
}
.item_request .agentCusRow span{
    color:#9B9B9B !important;
    font-size:14px;
    padding-inline-end:10px;
    display:none;
}
.editandtrash{
    display:none !important;
    position:absolute;
    top:0;
}
.editandtrash:lang(en){
    right:0;
}
.editandtrash:lang(fa){
    left:0;
}
@media only screen and (max-width:991.98px){
    .item_Country{
        margin-top:10px;
    }
    .item_Country,.item_TrackingCode,.item_Created{
        justify-content:flex-start !important;
        padding:3px 5px !important;
    }
    .item_request .agentCusRow span,.editandtrash{
        display:inline-block !important;
    }
}