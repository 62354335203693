.myProCirlce5{
    position:absolute;
    border-radius:50%;
    width:100px;
    height:100px;
    display:inline-block;
    background-color:#0a0a0a00;
    border-top:4px solid black;
    border-left:4px solid black;
    border-right:4px solid black;
    border-bottom:4px solid black;
    transform:rotate(-31deg);
}
#errorLabelStatus{
    color:black;
}

.errorLabelStatus
{
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.newHelpPAssLabel{
    padding-inline-start: 10px;
}
.fullwithcol{
    padding:0;
}
