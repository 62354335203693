.CirRect2{
  background-color: #ffffff00;
  color: black;
  border-radius: 20%;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  transition: .8s;
  cursor: pointer;
}
.CirRect2:hover {
  background-color: rgb(255, 255, 255);
}
.CirRect2:hover svg{
  color:black !important;
  fill:black !important;
}
.CirRect2:hover path{
  fill:black;
}
.CirRect2:hover span{
  color:black !important;
}
@media only screen and (max-width:768.98px){
  .CirRect2{
    height:60px;
    width:250px;
    border-radius:10px;
    justify-content:flex-start;
    padding:0 15px;
    margin-bottom:10px;
  }
}