
.backtohome:hover{
  color: #000000;
  text-decoration: underline;
}
#inputpassword2{
padding-inline-start: 41px;
background-color: transparent;
color: white !important;
border-color: #5a59593b;
margin-bottom: 7px;
}