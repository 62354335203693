.total_terms{
    display: flex;
    flex-wrap:wrap;
    padding:60px 15px;
}
.box_termlog{
    margin-bottom:25px;
}
.termlog{
    width:100%;
    display:flex;
    min-height:100px;
    flex-wrap:wrap;
}
.title_termlog{
    height:100%;
}
.txt_termlog{
    height:100%;
}
.title_termlog :is(h1,h2,h3,h4,h5,h6){
    font-size:25px !important;
    font-weight:700 !important;
    color:#313131 !important;
}
.txt_termlog p{
    font-size:16px !important;
    color:#6D6D6D !important;
}
.txt_termlog :is(h1,h2,h3){
    font-size:23px !important;
    color:#9B9B9B !important;
    font-weight:700 !important;
}
.txt_termlog :is(h4,h5,h6){
    font-size:20px !important;
    color:#9B9B9B !important;
}
@media only screen and (max-width: 767.98px){
    .box_termlog{
        padding:0;
    }
    .title_termlog{
       margin-bottom:12px;
    }
}
@media only screen and (max-width: 500.98px){
    .total_terms{
        padding:60px 0;
    }
}