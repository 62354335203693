.content_page{
    display:flex;
    min-height:100vh;
    flex-direction:column;
    background-color:#F6F6F6;
}
.header_page,.body_page,.footer_page{
    display:flex;
    flex-wrap:wrap;
}
.header_page{
    position:relative;
    overflow:hidden;
    border-radius:0 0 20px 20px;
    max-height:250px;
    padding:0;
    display:flex;
    justify-content:center;
}
.header_page::before{
    content:"";
    background:#dbbebe url("./images/backheadpage.png") no-repeat;
    padding:20px 0;
    width:100%;
    height:100%;
    background-size:cover;
    filter:blur(0px);
    -webkit-filter:blur(0px);
    position:absolute;
    bottom:0;
    top:0;
    left:0;
    right:0;
}
.top_header_page,.bottom_header_page{
    display:flex;
    min-height:110px;
}
.bottom_header_page{
    min-height:140px;
    flex-wrap:wrap;
    justify-content:center;
    align-items:baseline;
}
.titlehead_page{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding:0;
}
.icohead_page{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding:0;
}
.titlehead_page h3{
    font-size:27px !important;
    font-weight:700;
}
.logohead_page{
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
    padding:0;
}
.logohead_page img{
    max-height:70px;
    max-width:100%;
}
#searchbox_pages{
    height:55px;
    margin:0;
    position:relative;
    margin-top:-5px;
}
#input_search_box{
    height:55px;
    border-radius:12px !important;
    border:0;
    background-color:#fff !important;
    color:#707070 !important;
    font-size:14px;
}
.bottom_header_page h4{
    text-align:center;
    font-size:20px !important;
    margin:11px 0 0 0;
    line-height:29px;
}
#searchbox_pages svg{
    position:absolute;
    top:calc(50% - 10px);
}
#searchbox_pages svg:lang(en){
    right:25px;
}
#searchbox_pages svg:lang(fa){
    left:25px;
}
.total_services{
    display:flex;
    flex-wrap:wrap;
    padding:30px 0 50px 0;
}
.box_servlog{
    max-height:250px;
    margin:10px auto;
    padding:0 10px;
}
.servlog{
    min-height:250px;
    padding:20px 15px;
    background-color:#fff;
    border-radius:10px;
    box-shadow:0 2px 3px rgb(234,234,234);
}
.title_servlog{
    min-height:25px;
    position:relative;
    margin-bottom:10px;
}
.title_servlog h5{
   font-size:17px !important;
   color:#313131 !important;
}
.title_servlog::before{
    content:"";
    width:30px;
    height:2px;
    background-color:#D3A281;
    position:absolute;
    bottom:-5px;
}
.title_servlog:lang(en):before{
    left:0;
}
.title_servlog:lang(fa):before{
    right:0;
}
.txt_servlog{
    max-height:175px;
    overflow-y:auto;
}
.txt_servlog p{
    font-size:16px !important;
    color:#6D6D6D !important;
}
.footer_page{
    padding:0 15px 50px 0;
}
.footer_page .about-us{
    justify-content:center;
    padding-top:50px;
    margin-bottom:10px;
    border-top:2px solid #D3A281;
    flex-wrap:wrap
}
.footer_page .about-us .spanAbout p>span{
    color:#E0C9BE;
}
.footer_page .spanAbout a{
    color:#515050 !important;
}
@media only screen and (max-width: 600.98px){
    .box_servlog{
        width:100%;
        max-width:500px;
    }
    .footer_page .about-us{
        padding-top:30px;
    }
}
@media only screen and (max-width: 500.98px){
    .titlehead_page h3{
        font-size:19px !important;
    }
    .bottom_header_page h4{
        font-size:17px !important;
    }
    .logohead_page img {
        max-height:40px;
    }
    #searchbox_pages{
        height:45px;
        margin-top:-25px;
    }
    #input_search_box{
        height:45px;
    }

}