::-webkit-file-upload-button {
  display: none;
}
.myProCirlce4{
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: inline-block;
  background-color: #0a0a0a00;
  border-top: 3px solid transparent;
  border-left: 4px solid #000000;
  border-right: 4px solid #000000;
  border-bottom: 4px solid #000000;
  transform: rotate(-31deg);
}
.css-e363es-MuiFormControl-root{
  width:22% !important;
}
.importLabel{
    color: rgb(255, 255, 255);
    border-color: #f9fafb;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    width: 100%;
}
.importLabel2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 5px;
  color: white;
  font-family: 'IRANSansBlack';
  font-size: 14px;
}