.mainstyle{
  	background-image:url('./images/left.png');
  	background-position:center center;
  	background-repeat:no-repeat;
  	background-size:cover;
  	border-radius:10px;
    padding:0px;
    height:100%;
    position:relative;
}
.mainRowPro{
    min-height:100vh;
}
.LeftNavBar{
    z-index:999;
    height:calc(100vh - 40px);
    max-height:fit-content;
    position:sticky;
    top:20px;
    bottom:20px;
}
.ProDcDeactive{
    color: rgb(255, 9, 9);
    font-size: larger;
    cursor: help !important;
}
.myProBtnDeActive{
    background-color: #0000003b !important;
}
.myStarLeftNav{
    display: flex;
    align-items: center;
    justify-content: center;
}
.myStarLeftNav>svg.star_dcplusm{
    position:relative;
    top:-3px;
    margin-inline-end:5px;
}
.btnto_hc{
    flex-direction:column;
    align-items:center !important;
}
.btnto_hc>label{
    color:black !important;
    font-size:15px !important;
    margin-bottom:20px;
}
.btnto_hc button{
    max-width:300px !important;
    margin:0 auto;
}
.box{
	/* background-color:rgba(255, 255, 255, 0.3); */
	font-family:sans-serif;
	text-align:center;
	line-height:1;
    -webkit-backdrop-filter:blur(20px);
	backdrop-filter:blur(20px);
	height:100%;
	border-radius:10px;
	padding:50px 0 40px 0;
}
.box_logo_img{
    max-height:70px;
    margin-bottom:20px;
}
.box_logo_img img{
    max-height:70px;
}
.box p{
    margin:0px !important;
    color:black;
    padding-top:5px;
    font-size:14px !important;
}
.box p:lang(fa){
    font-family:'MorabbaBlack'; 
}
.box p:lang(en){
    font-family:'NunitoSans';
}
.box button {
    display:flex !important;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content:flex-start;
}
.box h6{
    margin-top:10px !important;
    color:#545454 !important;
}
.box h4{
    color:#2f2f2f !important;
}
.box img{
    width:110px;
}
.btnDash{
    display:flex !important;
    align-items:center;
	margin-bottom:3px;
    width:90%;
    border-radius:14px;
    padding:8px 10px;
    border-radius:12px;
}
.btnDash svg {
    width:32px !important;
    height:32px !important;
    padding:0px 5px 0px 5px;
}
.iconsbox{
    display:flex;
    justify-content:center;
    flex-direction:column;
    flex-wrap:nowrap;
	align-items:center;
    padding:0 0 10px 0;
}
.iconsbox button {
    border-color:transparent !important;
}
.iconsbox button:focus {
    border-color:transparent;
}
.btn:focus{
    box-shadow:none !important;
}
.globalLang{
	display:flex;
    flex-direction:column;
    flex-wrap:nowrap;
    align-content:center;
    justify-content:flex-end;
    align-items:center;
    margin-top:75px;
}
.globalLang svg{
	width:28% !important;
    height:28% !important;
    padding:0px 5px 0px 5px;
}
.globalBtnHover{
	display:flex !important;
    justify-content:center;
    align-items:center;
    border-radius:22px !important;
}
.faEnP{
	font-size:67%;
    padding-top:0px !important;
    color:black !important;
}
.faEnP a {
	color:#000000 !important;
    text-decoration:none !important;
}
.mainstyle .box .CreatableSelectLeft3,.mainstyle .box .CreatableSelectLeft2,.mainstyle .box .CreatableSelectLeft{
    position:absolute !important;
    bottom:20px;
    left:0;
    right:0;
    max-width:160px;
    margin:0 auto;
}
.mainstyle .box #Service *{
   border:none;
}
.mainstyle .box #Service .css-1okebmr-indicatorSeparator{
    opacity:0;
}
.CreatableSelect .css-qc6sy-singleValue{
    color:rgb(0, 0, 0) !important;
}
.close_navbar{
    display:none;
}
@media only screen and (max-width:1300.98px) and (min-width:992px){
    .btnDash{
        padding:8px 0;
    }
    .LeftNavBar{
        width:20%;
    }
    .cont_apptel{
        width:80%;
    }
    .box_summary svg{
        width:45px;
    }
    .box_summary .homeCell2 h4{
        font-size:17px !important;
    }
}
@media only screen and (max-width:850.98px) and (min-width:768px){
    .btnDash{
        padding:8px 0;
    }
}
@media only screen and (min-width:767.98px){
    .mainstyle{
        min-height:calc(100vh - 40px);
        height:unset;
    }
    .box{
        -webkit-backdrop-filter:none;
        backdrop-filter:none;
    }
    @media only screen and (min-height:100vh){
        .LeftNavBar{
            height:fit-content; 
        }
        .mainstyle{
            height:100%;
        }
    } 
}
@media only screen and (max-width:767.98px){
    .box p{
        color:black !important;
    }
    .LeftNavBar,.mainRowPro{
        min-height:unset;
    }  
    .btnDash{
        padding-inline-start:18px;
    }    
}