.DisDisService{
    background-color:#f6f6f6;
    display:flex;
    flex-direction:column;
    align-items:stretch;
    text-align:start;
    margin:10px 0;
    border-radius:10px;
    padding:15px;
}
.SuggestedServices{
    display:flex;
    flex-wrap:wrap;
    margin-top:10px;
    border-top:1px dashed #f7f7f7;
    padding:15px 10px;
}
.SuggestedServices>.hidenItem>span{
    padding:0;
}
.SuggestedServices.diffcssdiv{
    border-top:none;
}
.DisDisService2{
    background-color:#f6f6f6;
    display:flex;
    text-align:start;
    flex-wrap:wrap;
    align-items:flex-start;
    margin:10px 0;
    border-radius:10px;
    padding:15px;
}
.DisDisService span,.DisDisService2 span{
    padding: 5px !important;
}
.DisDisService2>.Cus2Inputs{
    padding:0;
}
.myDisSubmitService{
    border-radius:8px;
    margin:4px;
}
.DisDisService2>.myDisSubmitService{
    margin:0 auto;
    height:44px;
    display:flex;
    justify-content:center;
    align-items:center;
}
.myDisDisServiceToAccept{
    display: flex;
    flex-wrap:wrap;
    align-content: space-around;
    text-align:start;
}
.colm_contentlist{
    flex-direction:column;
    padding:0;
}
.btns_SugServices{
    margin:10px 0;
}
.txt_SugServices{
    display: flex;
    align-items:center;
    justify-content:flex-start;
    padding:0;
}
.inputAndlabel.porsant_sug{
    position:relative;
}
.porsant_sug::before{
    content:"%";
    position:absolute;
    top:0;
    right:15px;
    height:calc(100% - 7px);
    width:15px;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    color:#313131;
}
.porsant_sug input:lang(fa){
    padding-inline-start:23px;
}
.porsant_sug input:lang(en){
    padding-inline-end:23px;
}
.btns_SugServices{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    padding:0;
}
.btns_SugServices button{
   width:100px;
}
@media only screen and (max-width:991.98px){
    .DisDisService2>.myDisSubmitService{
        width:calc(100% - 10px);
    }
    .SuggestedServices.diffcssdiv{
        border-top:1px dashed #f7f7f7;
    }
}
@media only screen and (max-width:600px){
 .btns_SugServices{
    justify-content:flex-start;
 }
}
