.backtohome{
  display: flex;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px !important;
}
.backtohome:hover{
  color: #000000;
  text-decoration: none;
  animation-name: example;
  animation-duration: 1s;
}
.loginMainRow .backtohome{
  margin-bottom:20px !important;
}
@keyframes example {
  from {color: white;}
  to {color: black;}
}
.form_resetpass .LogoMobile{
  margin:30px auto;
}