.mail_pagelog{
    margin-top:50px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-wrap:wrap;
    padding:0;
}
.mail_pagelog span{
    color:#313131 !important;
    font-size:16px !important;
}
.mail_pagelog strong{
    font-weight:700 !important;
    color:#D3A281 !important;
    font-size:17px !important;
}
.Social_contctus{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:30px;
    flex-wrap:wrap;
}
.Social_contctus>div{
    display:flex;
    align-items:center;
    justify-content:center;
}
.Social_contctus>div>div{
    width:27px;
    height:27px;
    background-color:#D3A281;
    border-radius: 50%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 3px;
    transition:transform .2s;
}
.Social_contctus>div>div:hover{
    transform:scale(1.2);
}
.Social_contctus span{
    color:#6D6D6D !important;
    font-size:16px !important;
    padding-inline-end:50px;
}
.Social_contctus svg{
    margin:0;
}
@media only screen and (max-width:660.98px){
    .mail_pagelog,.Social_contctus{
        justify-content:flex-start;
    }
    .mail_pagelog strong{
        margin-top:10px;
    }
    .Social_contctus span{
        padding-inline-end:0;
    }
    .Social_contctus>div{
        margin-top:10px;
    }
}