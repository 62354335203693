.Cus2Step1GoldP{
    color:#D3A281 !important;
    font-size:24px !important;
    margin-inline-end:5px;
    /* font-family:"NunitoSansSemiBold" !important; */
}
.Cus2Step1BlackP{
    color:black !important;
    font-size:24px !important;
}
.UploadedFileBasicForm{
    color: #313131 !important;
    cursor: pointer;
}
.back_pagelink{
    cursor:pointer;
}
.personalInfo .Step1e1 a>svg,.personalInfo>.Step1>a>svg{
    margin-inline-end:3px;
}
.personalInfo .Step1e1 a>svg:lang(fa),.personalInfo>.Step1>a>svg:lang(fa){
    transform:rotate(180deg);
    display:inline-block;
}
.personalInfo>.Step1{
    position:relative;
}
.personalInfo>.Step1>a{
    color:black !important;
    position:absolute;
}
.personalInfo>.Step1>a:lang(fa){
    left:0
}
.personalInfo>.Step1>a:lang(en){
    right:0
}

.Cus2Step1SmallP{
    color:#8F8F8F !important;
}
.Cus2PersonalInfo{
    position:relative;
    margin:25px 0;
    padding:0;
}
.Cus2PersonalInfo::before,.Step1e1::before{
    content:"";
    height:2px;
    width:35px;
    background-color:#D3A281;
    position:absolute;
    bottom:-4px;  
}
.Cus2PersonalInfo:lang(en):before,.Step1e1:lang(en)::before{
    left:0;
}
.Cus2PersonalInfo:lang(fa):before,.Step1e1:lang(fa)::before{
    right:0;
}
.Cus2PersonalInfoMidline{
    display:flex;
    justify-content:center;
    position:relative;
    margin:25px 0 15px 0;
}
.Cus2PersonalInfoMidline::before{
    content:"";
    height:2px;
    width:40px;
    background-color:#D3A281;
    position:absolute;
    bottom:-5px;
}
.Step1{
    display:flex;
    flex-wrap:wrap;
}
.part_of_form summary{
    display:flex;
    align-items:center;
}
.part_of_form summary>svg{
    margin-inline-end:8px;
    display:inline-block;
    transition:transform .5s;
}
.part_of_form summary>svg:lang(fa){
    transform:rotate(180deg);
}
details.part_of_form[open] summary>svg{
    transform:rotate(90deg);
}
details.part_of_form[open] .Cus2Inputs{
	-webkit-animation:fadeInScale .5s ease-in-out;
	-moz-animation:fadeInScale .5s ease-in-out;
	animation:fadeInScale .5s ease-in-out;
}
@keyframes fadeInScale{
  0% {
  	transform:scale(.9);
  	opacity:0;
  } 
  100% {
  	transform:scale(1);
  	opacity:1;
  }
}
/* details.part_of_form .Cus2Inputs{
	-webkit-animation:fadeoutScale .7s ease-in-out;
	-moz-animation:fadeoutScale .7s ease-in-out;
	animation:fadeoutScale .7s ease-in-out;
}
@keyframes fadeoutScale{
  0% {
  	transform:scale(1);
  	opacity:1;
  } 
  100% {
  	transform:scale(.9);
  	opacity:0;
  }
} */
.Cus2PersonalInfo p,.Cus2PersonalInfoMidline p{
    color:black !important;
    font-size:24px !important;
    margin-bottom:0;
}
.Cus2PersonalInfoMidline p{
    text-align:center;
}
.MyCheckBox1{
    display:flex;
    flex-direction:row;
    text-align:center;
    height:10px !important;
    color:beige !important;
}
.mainRowPro input[type=checkbox] {
    display:flex;
    flex-direction:row;
    text-align:center;
    height:13px !important;
    color:beige !important;
    margin-inline-end:5px;
    filter:brightness(100%) sepia(0) !important;
}
.inputAndlabelP{
    color:black !important;
    font-size:17px !important;
    margin-top:10px;
}
.MyCheckBox1Div,.languagesspeaks{
    display:flex;
    flex-wrap:wrap;
    padding:0;
}
.single_lineinput{
    margin-bottom:10px;
    padding:0;
}
.MyCheckBox1Div{
    align-items:baseline;
}
.MyCheckBox1Div p {
    color:black !important;
}
.MyCheckBox1Div label{
    color:black !important;
}
.checkspeak{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding:10px 7px;
}
.checkspeak p{
    margin:0;
}
.Cus2Inputs input[type=text],input[type=number],select,textarea{
    border:1px solid #B7B7B7 !important;
    border-radius:8px;
    padding-inline-start:10px;
    background-color:transparent;
    color:rgb(8, 8, 8) !important;
    margin-bottom:7px;
    height:calc(1.5em + .75rem + 13px) !important;
    width:100%;
}
.Cus2Inputs select {
    height:calc(1.5em + .75rem + 13px);
}
.Cus2Inputs textarea {
    height:calc(1.5em + .75rem + 56px) !important;
}
.Cus2Inputs input:hover{
    border-color:black !important;
}
.Cus2Inputs input:focus{
    outline:none;
}
.Cus2Inputs label{
    color:#6f6f6f !important;
}
.inputAndlabel{
    display:flex;
    flex-direction:column;
    padding:0 7px;
}
.Cus2Inputs{
    display:flex;
    flex-wrap:wrap;
}
.inputAndlabelRadio{
    display:flex;
    flex-direction:row;
    align-items:center;
    width:15%;
}
.inputAndlabelRadio p {
    color:black !important;
    margin-bottom:5px !important;
}
.inputAndlabelRadio label{
    margin-bottom:5px;
}
.cusS1SaveCol{
    display:flex;
    justify-content:space-between;
    padding-top:1.8%;
    flex-wrap:wrap;
}
.cusS1SaveCol>div{
    padding:0 15px;
}
.cusS1SaveCol .btn-outline-light{
    color:black !important;
    border-color:#c3c3c3;  
}
.myUploadFileCusCenter{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
}
.cusS1SaveCol .btn-outline-light,.cusS1SaveCol .btn-dark{
    border-radius:8px;
    width:150px;
    height:calc(1.5em + .75rem + 10px) !important;
}
.btn-dark:hover{
    color:black !important;
}
.btn-dark:hover .notepad2Cus2Pad path{
    stroke:black !important;
}
.botCusDraftCol{
    display:flex;
    position:relative;
}
.botCusDraftCol .btn-outline-light{
    border-color:transparent;
    color:#D3A281 !important;
}
.botCusDraftCol svg {
    margin-bottom:5px;
    margin-inline-end:5px;
}
.ReqiredCus{
    font-size:14px !important;
    color:crimson !important;
}
.inputAndlabel label{
    display:flex;
}
.mainRowPro2{
    height:731px;
    overflow-y:auto;
    scrollbar-color:transparent;
    box-shadow:hsl(0deg 0% 80% / 0%) 0 0 16px;
    background-color:#ffffff;
    border-radius:-1px;
    padding:0px;
    margin-inline-start:0%;
}
.cvUploadBasicInfo{
    display:flex;
    justify-content:center !important;
    margin-bottom:30px;
    flex-wrap:wrap;
    padding:0!important;
}
.cvUploadBasicInfo>p{
    color:#313131 !important;
    font-size:15px !important;
    padding:0;
    margin-bottom:25px!important;
}
.imgup_brownBox2{
    padding:0 8px;
    border-radius:8px !important;
    background-color:#fff;
    border:1px solid #313131;
    display:flex;
    justify-content:center;
    align-items:center;
    height:44px;
    position:relative;
}
.imgup_brownBox2 #imgupload{
    width:100%;
    height:100%;
    border-radius:8px !important;
    position: absolute;
    top:0;
    left:0;
}
.imgup_brownBox2>a#personPicPen{
    color:#313131 !important;
    font-size:17px !important;
    text-align:center;
    position:relative !important;
    top:unset !important;
}
@media only screen and (max-width:1199.98px) and (min-width:992px){
    .MyCusButtons{
        width:50% !important;
        max-width:unset;
    }
}
@media only screen and (max-width:991.98px){
    .inputAndlabel{
        margin-bottom:10px;
        padding:0 5px;
    } 
    .single_lineinput{
        margin-inline-end:0;
    }
}
@media only screen and (max-width:600.98px){
    .Cus2Step1GoldP,.Cus2Step1BlackP{
        font-size:16px !important;
    }
    .Cus2Step1GoldP{
        white-space:nowrap;
        margin-bottom:5px;
    }
    .mainRowPro{
        padding:30px 15px;
    }
    .Cus2PersonalInfoMidline p{
        font-size:17px !important;
    }
}
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
    .Cus2Inputs input[type="text"], input[type="number"], select, textarea{ 
        border:1px solid #ccc !important;
        background-color:transparent;
        color:#080808 !important;
    }
}
