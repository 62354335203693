@import url('./regaStep2/Register.css');
@import url("./regcStep2/Register.css");
@import url('./regStep6/Register.css');
@import url('./regChooseService/ChooseService.css');
@import url('./regChooseDistributer/regChooseDis.css');
@font-face{
  font-family:'iconsax';
  src: url('fonts/iconsax.eot?yjegnn');
  src: url('fonts/iconsax.eot?yjegnn#iefix') format('embedded-opentype'),
    url('fonts/iconsax.ttf?yjegnn') format('truetype'),
    url('fonts/iconsax.woff?yjegnn') format('woff'),
    url('fonts/iconsax.svg?yjegnn#iconsax') format('svg');
  font-weight:normal;
  font-style:normal;
  font-display:block;
}
.mainRegisterheader{
  display:flex;
  justify-content:space-between;
  padding:0px;
}
.NewHeaderWithHomeIcone{
  display: flex;
  align-items: flex-start;
  align-content: flex-end;
}
.MyHomeNewReg{
  margin-bottom:50px;
}
.form_regisster .rectangle{
    min-height:calc(100vh - 70px);
}
.subRegisterrightheader{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-end;
  padding:0;
}
.backtohome{
  margin-bottom:30px;
}
.subRegisterleftheader{
  display:flex;
  justify-content:flex-end;
  padding:0;
  position:relative;
}
.subRegisterleftheader img{
  max-height:70px;
  max-width:100%;
}
.subRegisterrightheader h4{
  margin-bottom:15px;
  position:relative;
}
.subRegisterrightheader h4::before{
  content:"";
  width:35px;
  height:1px;
  background-color:#fff;
  position:absolute;
  bottom:-10px;
}
.subRegisterrightheader h4:lang(fa){
  right:0;
}
.subRegisterrightheader h4:lang(en){
  left:0;
}
.RegisterHeaderp{
  width:100%;
  margin-top:0px;
  font-size:14px !important;
  color:white !important;
}
.RegisterHeaderp:lang(en){
  font-family:'NunitoSans';
}
.progressRow{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
}
.selectProfile{
  padding:20px 0px !important;
  display:flex;
  align-items:stretch;
  justify-content:space-evenly;
}
.circle{
  background-color:transparent;
  color:black;
  border-radius:50%;
  height: 140px;
  width: 140px;
  display:flex;
  justify-content:center;
  text-align:center;
  align-items:center;
  transition:.8s;
}
.BriefcaseA{
  display:flex;
  color:#ffffff;
  text-decoration:none !important;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  height:100% !important;
}
.circle:hover{
  background-color:rgb(255, 255, 255);
}
.circle:hover svg{
  color:black !important;
  fill:black !important;
}
.circle:hover path{
  fill:black;
}
.circle:hover span{
  color:black !important;
}
.ico_backhome{
  position:absolute;
  top:-20px;
  display:none;
}
.ico_backhome:lang(fa){
  left:20px;
}
.ico_backhome:lang(en){
  right:20px;
}
.myshapes{
  display:flex;
  justify-content:center;
  padding:20px;
}
.mycircle{
  border:rgb(238, 236, 236) 4px solid;
  border-radius:50%;
  width:100px;
  height:100px;
  display:flex;
  justify-content:center;
  align-items:center;
}
.mycircle h6{
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  text-align: center;
}
.myProCircle{
  position:absolute;
  border-radius:50%;
  width:100px;
  height:100px;
  display:inline-block;
  background-color:#0a0a0a00;
  border-top:3px solid transparent;
  border-left:3px solid transparent;
  border-right:4px solid black;
  border-bottom:3px solid #00000000;
  transform:rotate(-61deg);
}
.mytext{
  display:flex;
  flex-direction:column;
}
.mytext h6{
  font-size:18px !important;
}
.subRegisterrightheader a{
  font-size:x-small;
  text-decoration:none;
}
.subRegisterrightheader h4:lang(en){
  font-family:'NunitoSansBold' !important;
  color:white;
  font-size:28px;
}
.subRegisterrightheader h4:lang(fa){
  font-family:'MorabbaBlack' !important;
  color:white;
  font-size:28px;
}
.progressStyle{
  width:100%;
  display:flex;
  align-items:stretch;
  justify-content:center;
  align-content:center;
  height:fit-content;
}
.lawuserpass_txt{
  justify-content:flex-start;
  padding:0;
  margin-bottom:5px;
}
.lawuserpass_txt svg{
  padding-inline-end:7px;
}
.backcircle{
  color:#d7d7d7 !important;
  transform:rotate(-90deg);
  position:absolute !important;
}
.frontcircle{
  color:#000000 !important;
}
.css-1rn30mb-MuiCircularProgress-root{
  width:100px !important;
  height:100px !important;
}
.css-ol7wxs-MuiTypography-root{
  margin:0;
  font-family:'IRANSansBlack' !important;
  font-weight:bolder !important;
  font-size:90% !important;
  line-height:1.66 !important;
  letter-spacing:0.03333em;
  color:rgb(249 246 246) !important;
  position:absolute;
}
.chooseyour{
  display:flex;
  align-content:flex-start;
  padding:0px 10px!important;
  /* align-items:center; */
  flex-direction:column;
  justify-content:center;
  font-family:'IRANSansBlack';
  color:white;
}
.step1{
  font-size:14px;
}
.choosText:lang(en){
  font-family:'NunitoSansBold' !important;
}
/* .css-1iz24ed-MuiChip-root{
  font-size:0.8125rem;
  display:-webkit-inline-box;
  display:-webkit-inline-flex;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-align-items:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  -webkit-justify-content:center;
  justify-content:center;
  color:rgba(255, 255, 255, 0.87) !important;
  border-radius:16px;
  white-space:nowrap;
  -webkit-transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition:background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor:default;
  outline:0;
  -webkit-text-decoration:none;
  text-decoration:none;
  border:0;
  padding:0;
  vertical-align:middle;
  box-sizing:border-box;
  display:contents !important;
} */
.css-1iz24ed-MuiChip-root:lang(en){
  font-family:'NunitoSansBold' !important; 
}
.css-1iz24ed-MuiChip-root:lang(fa){
  font-family:'MorabbaBlack' !important;
}
.css-1iz24ed-MuiChip-root:hover{
  color:rgba(0, 0, 0, 0.87) !important;
}
.css-1iz24ed-MuiChip-root .MuiChip-icon{
  color:#ffffff !important;
  margin-left:0px!important;
  margin-right:0px!important;
}
.css-1iz24ed-MuiChip-root .MuiChip-icon:hover{
  color:#000000 !important;
  text-decoration:none !important;
}
.isax{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:'iconsax' !important;
  /* speak:never; */
  font-style:normal;
  font-weight:normal;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
.isax-briefcase1 .path1:before{
  content:"\ede8";
  color:rgb(41, 45, 50);
}
.isax-briefcase1 .path2:before{
  content:"\ede9";
  margin-left:-1em;
  color:rgb(41, 45, 50);
  opacity:0.4;
}
.progressStyle .css-rmz4vk{
  width:auto !important;
  height:auto !important;
  position:inherit !important;
}
.progressStyle .css-1o8dslu{
  position:static !important;
}
.footer-copy-Register{
  display:flex;
  justify-content:center;
  color:#ffffff82;
  font-family:'NunitoSansSemiBold';
  /* font-size:100%; */
  align-items:baseline;
  margin-top:0px;
}
@media only screen and (max-width:768.98px){

}
@media only screen and (max-width:768.98px){
  .selectProfile{
    flex-direction:column;
    padding-bottom:0 !important;
  }
  .subRegisterleftheader{
    justify-content:center;
    margin-bottom:30px;
  }
  .mainRegisterheader{
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin: auto;
  }
  .NewHeaderWithHomeIcone{
    flex-direction: column-reverse;
  }
  .form_regisster .rectangle{
    padding:35px 20px 10px 20px;
    /* height:100vh !important; */
  }
  .circle{
    height:60px;
    width:200px;
    border-radius:10px;
    justify-content:flex-start;
    padding:0 15px;
    margin-bottom:10px;
  }
  .BriefcaseA{
    flex-direction:row;
    justify-content:flex-start;
  }
  .BriefcaseA svg{
    margin-inline-end:10px;
    width:40px;
  }
  .ico_backhome{
    display:block;
  }
  .txt_backhome,.progressRow{
    display:none;
  }
}