.txt_aboutus{
    padding:40px 15px 60px 15px;
}
.txt_aboutus :is(h1,h2,h3,h4,h5,h6){
    font-size:17px !important;
    color:#313131 !important;
    margin-top:30px;
}
.txt_aboutus p{
    font-size:15px !important;
    color:#6D6D6D !important;
}
@media only screen and (max-width: 767.98px){
    .txt_aboutus{
        padding:20px 0 60px 0;
    }
}