.main404{
    position: relative;
    overflow:hidden;
    height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    padding-bottom:40px;
}
.cus1Pic404 {
    display: flex;
    flex-direction: column;
    padding:20px;
}
.teleLogo404Div{
    display: flex;
    justify-content: center;
    margin-bottom:30px;
    height:80px;
}
.teleLogo404{
    max-width:100%;
    max-height:80px;
}
.mainPic404Div{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.mainPic404Div img{
    max-width:100%;
    max-height:190px;
}
.youdont404{
    display: flex;
    justify-content: center;
    position: relative;
}
.youdont404::after{
    content:"";
    position:absolute;
    height:1px;
    width:60%;
    margin:0 auto;
    bottom:0;
    background-color:#d0d0d0;
}
.youdont404 p {
    color: #D3A281 !important;
    font-size: 28px !important;
    text-align: center;
}
.leftCircle404-01{
    /* background-color: #D3A281; */
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 22%;
    left: 17%;
    opacity: 0.5;
    filter: blur(76px);
}
.leftCircle404-02{
    /* background-color: #D3A281; */
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    top: 26%;
    left: 74%;
    opacity: 0.5;
    filter: blur(76px);
}
.leftCircle404-03{
    /* background-color: #D3A281; */
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 92%;
    left: 57%;
    opacity: 0.5;
    filter: blur(76px);
}
.Social404{
    display:flex;
    position:absolute;
    bottom:30px;
    width: 200px;
    justify-content: space-evenly;
}
.Social404:lang(en){
    left:30px;
}
.Social404:lang(fa){
    right:30px;
}
.Social404>div{
    width:30px;
    height:30px;
    background-color:#313131;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.btn_404 a.btn{
    border-radius:5px !important;
}
@media only screen and (max-width:767.98px){
    .Social404{
        margin:0 auto;
        left:unset !important;
        right:unset !important;
        bottom:20px;
    }
}