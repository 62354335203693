.CusStep2Tick{
    display: flex;
    justify-content: center;
    padding:7% 0;
    flex-direction: column;
    align-items: center;
    width:100%;
}
.CusStep2TickP{
    color: black !important;
    font-size: 24px !important;
    padding-top: 10px;
    text-align:center;
}
.Cus2Step1SmallPBrown2 {
    color: #D3A281 !important;
    font-size: 16px !important;
    text-align:center;
}
.CusStep2TickP3{
    color: black !important;
    font-size: 16px !important;
    padding-top: 20px;
    text-align:center;
}
.notepad2Cus2Pad{
    margin-inline-end: 10px;
}
.CusStep2Tick .btn{
    border-radius: 8px !important;
}
@media only screen and (max-width: 600.98px){
    .Cus2Step1GoldP,.Cus2Step1BlackP{
        font-size:14px !important;
    }
}