.selectProfile2{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding:20px 0;
}
.CirRect{
  background-color: transparent;
  color: black;
  border-radius: 20%;
  height: 165px;
  width: 165px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  transition: .8s; 
  margin:0 auto 10px auto;
}
.CirRect{
  background-color: #ffffff1f;
  cursor: pointer;
}
.CirRect:hover{
  background-color: rgb(255, 255, 255);
}
.CirRectDeactive:hover{
  background-color: rgb(255 255 255 / 28%);
}
.CirRect:hover svg{
  color:black !important;
  fill:black !important;
}
.CirRect:hover path{
  fill:black;
}
.CirRect:hover span{
  color:black !important;
}
.CirRectDeactive:hover svg{
  color:rgba(0, 0, 0, 0.555) !important;
  fill:rgba(0, 0, 0, 0.726) !important;
}
.CirRectDeactive:hover path{
  fill:black;
}
.CirRectDeactive:hover span{
  color:rgba(0, 0, 0, 0.644) !important;
}
@media only screen and (max-width:768.98px){
  .selectProfile2{
    justify-content:flex-start;
  }
  .CirRectDeactive,.CirRect{
    height:60px;
    width:200px;
    border-radius:10px;
    justify-content:flex-start;
    padding:0 15px;
    margin-bottom:10px;
  }
}

