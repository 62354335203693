.myreq p{
    color:black !important;
    font-size:24px !important;
}
.cus1Pic{
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding:50px;
}
.myreq_img img{
    max-height:200px;
}
.youdont{
    display:flex;
    flex-direction:row;
    justify-content:center;
}
.youdont p {
    color:black !important;
    font-size:24px !important;
}
.setupNew{
    display:flex;
    flex-direction:row;
    justify-content:center;
    padding-top:20px;
}
.setupNew .btn{
    border-radius:0.5rem !important;
}