.progressRowM6{
    margin-top:155px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
}
.message_sucssesreg{
    margin:0;
    min-height:230px;
}
.progressRowM6.serv_page{
    margin-top:0 !important;
}
.progressRowM6.serv_page p{
    text-align:center !important;
}
.progressRowM6 .login-btnStep6{
    max-width:250px;
}
.login-btnStep6{
    width:100%;
    text-align-last:center;    
}
.done_mesaage{
    display:flex;
    justify-content:center;
    align-items:center;
    margin:10px 0;
}
.done_mesaage h5{
    margin:0 !important;
    padding-inline-start:10px;
}
.message_sucssesreg .LastStepRegText{
    min-height:50px;
    display:flex;
    justify-content:space-between;
    flex-direction:column;
}
.message_sucssesreg .LastStepRegText p{
    margin:0 !important;
}
.message_sucssesreg .checkmailmessage{
    text-align: center;
}
.message_sucssesreg svg{
    margin-inline-end:5px;
}
@media only screen and (max-width:600px){
    .mytext6{
        padding-top:15px;
        display:flex;
        flex-direction:column;
        align-items:center;
    }
    .login-btnStep6{
        margin-top:0px;
    }
}