
#IDNumber{
  padding-inline-start: 25px;
  background-color: transparent;
  color: white ;
  border-color: #5a59593b;
  margin-bottom: 7px;
  line-height: 1.85 !important;
  display: flex;
}
#IDNumberM{
  padding-inline-start: 25px;
  background-color: transparent;
  color: white ;
  border-color: #5a59593b;
  margin-bottom: 7px;
  line-height: 1.85 !important;
  display: none;
}
#inputrefCode{
  padding-inline-start: 25px;
  background-color: transparent;
  color: white ;
  border-color: #5a59593b;
  margin-bottom: 7px;
  line-height: 1.85 !important;
  display: flex;
}
#MyIDNumberplaceholderM{
  display: none;
}
.small-input {
  -moz-appearance: textfield;
}
.small-input::-webkit-inner-spin-button {
  display: none;
}
.small-input::-webkit-outer-spin-button,
.small-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 600px) {
#IDNumber{
  padding-inline-start: 25px;
  background-color: transparent;
  color: white ;
  border-color: #5a59593b;
  margin-bottom: 7px;
  line-height: 1.85 !important;
  display: none;
}
#IDNumberM{
  padding-inline-start: 25px;
  background-color: transparent;
  color: white ;
  border-color: #5a59593b;
  margin-bottom: 7px;
  line-height: 1.85 !important;
  display: flex;
}
#MyIDNumberplaceholderM{
  display: flex;
}
#MyIDNumberplaceholder{
  display: none;
}
}