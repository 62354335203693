.test{
    color:black !important;
}
.mainRowPro{
    scrollbar-color:transparent;
    box-shadow:hsl(0deg 0% 80% / 22%) 0 0 16px;
    background-color:#fff;
    border-radius:20px;
    padding:30px;
    overflow-x: auto;
}
.fprm_profile{
    display:flex;
    flex-wrap:wrap;
}
.fprm_profile h4{
    color:black !important;
    padding-top:20px;
    font-size:20px !important;
}
.penAndPerson{
    display:flex;
    flex-direction:row;
}
.pen{
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:center;
    align-items:center;
    margin-bottom:0.5rem;
    padding-inline-start:10px;
    padding-top:20px;
}
.redAttention,.greyAttention{
    display:flex;
    align-items:center;
    flex-direction:row;
    padding-top:20px;
}
.redAttention p{
    font-size:10px;
    color:#ff2727 !important;
    margin-bottom:0px !important;
    padding-inline-start:5px;
}
.greyAttention p{
    font-size:10px;
    color:#313131 !important;
    margin-bottom:0px !important;
    padding-inline-start:5px;
}
.personalInfoRowTwo,.personalInfoRowOne{
    display:flex;
    flex-wrap:wrap;
    padding:0px;
}
.personalInfoRowOne{
    padding:20px 0px;
    justify-content:space-between;
}
.rowTwoColOne,.personalInfoRowThree{
    display:flex;
    flex-wrap:wrap;
    padding:0;
    padding-inline-end:10px;
}
.personalInfoRowTwo label,.personalInfoRowThree label{
    color:black !important;
    display:flex !important;
    padding-bottom:5px;
}
.rowTwoColThree label{
    color:#AFAFAF !important;
}
.personalInfoRowTwo .placeholderPro,.personalInfoRowThree .placeholderPro{
    margin-top:35px;
    margin-inline-start:-15px;
    font-size:14px !important;
}
.rowTwoColOne .form-select,.personalInfoRowTwo .form-select{
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    line-height:1rem !important;
}
.fprm_profile .personalInfoRowTwo input{
    padding-inline-start:10px;
    background-color:transparent;
    color:rgb(8, 8, 8) !important;
    border-color:#5a59593b;
}
.input-placeholder{
    margin-top:20px;
    padding-inline-start:0;
}
.input-placeholder input,.input-placeholder select{
    height:45px !important;
    border-radius:5px !important;
    padding: 0 9px
}
.input-placeholder input:hover{
    cursor:pointer !important;
}
#proFirstName:valid + .placeholderPro{
    display:none !important;
}
#birthDate{
    margin-bottom:2px;
    width:100%;
    direction:ltr;
}
#inputproAbout{
    min-height:85px;
}
#personalSave,#personalCancel,#personPenBlue{
    display:none;
}
#personPen{
    cursor:pointer;
}
#files{
    flex-direction:column;
    margin-inline-start:-10px;
    color:blue !important;
}
#jobUploadLabel{
    margin-inline-start:-10px;
    color:blue !important;
}
#userNameLast{
    margin-bottom:0px;
    font-size:12px;
}
.form-control:disabled,.form-control[readonly]{
    background-color:#e9e9e900 !important;
    color:313131 !important;
    opacity:0.5;
}
.input-placeholder-jobUpload{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    padding-top:25px;
}
.input-placeholder-jobUpload svg{
    margin-bottom:13px;
    margin-inline-start:5px;
}
.placeholderPro,.placeholderProUpload,.placeholderProEmail{
    position:absolute;
    pointer-events:none;
    top:2px;
    bottom:2px;
    margin:auto;
    color:#313131;
    background-color:transparent;
    padding-inline-start:25px;
    opacity:0.8 !important;
}
#JobUpload{
    font-size:0;
    opacity:0;
}
#JobUpload[disabled] ~ #MyJobUploadplaceholder{
    background-color: #fff !important;
    color:#828282 !important;
    box-shadow:1px 1px 6px #a7a4a4 !important;
}
#MyJobUploadplaceholder{
    width:calc(100% - 15px);
    padding-inline-start: 10px;
    background-color: transparent;
    color: rgb(8, 8, 8) !important;
    height: 45px !important;
    border-radius: 5px !important;
    padding: 0 9px;
    display:flex;
    align-items:center;
    box-shadow:1px 1px 6px #a7a4a4;
}
.placeholderProEmail{
    opacity:0.0 !important;
}
.proBox{
    box-shadow:hsl(0deg 0% 90%) 0 0 16px;
    background-color:#fff;
    border-radius:20px;
    padding:0;
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:flex-start;
    align-items:center;
    height:335px;
    margin-top:25px;
}
.proBox .ShackeShapeSocial svg{
   width:25px;
   height:25px;
}
.ProSocial{
    display: flex;
    cursor: pointer;
}
#ScrollUp{
    color: #313131 !important;
    border-radius: 50%;
    border: 0;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;  
}
#ScrollUp:lang(en){
    right:-19px;
}
#ScrollUp:lang(fa){
    left:-19px;
}
.input-placeholder #ScrollUp>svg,.cusS1SaveCol #ScrollUp>svg{
    margin:0 !important; 
    position:relative;
    top:unset;
    right:unset;
    left:unset;
}
.ShackeShapeSocial{
    padding: 40px 10px;
    cursor: pointer;
    transition: padding 0s;
}
.ShackeShapeSocial:hover{
    padding:38px 10px;
    transition: padding 0s;
}
.brownBox{
    background-color:#d7d2d0;
    height:90px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:baseline;
    border-radius:20px 20px 0 0;
}
.proBox.mob_proBox{
    display:none;
}
.img_brownBox{
    width:110px;
    height:110px;
    border-radius:50%;
    border:2px solid #A3A3A3;
    margin-top:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    position: relative;
}
.img_brownBox img{
    height: 100%;
    border-radius: 50%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.imgup_brownBox{
    position:absolute;
    top:0;
    width:25px;
    height:25px;
    border-radius: 50%;
    background-color:#fff;
    border:1px solid #313131;
    display:flex;
    justify-content: center;
    align-items:center;
}
.imgup_brownBox:lang(en){
    right:0
}
.imgup_brownBox:lang(fa){
    left:0
}
#imgupload{
    width:25px;
    height:25px;
    border-radius: 50%;
    cursor:pointer;
    font-size:0 !important;
    opacity:0;
}
#personPicPen{
    position:absolute;
    top:2px;
}
label.proBoxLabel{
    margin-top:65px;
    color:black !important;
    font-size:16px !important;
}
.proBoxLabelDis{
    color:#919191 !important;
    margin-bottom:0px !important;
    font-size:14px;
}
.personalInfoRowFour{
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    margin-top:20px;
}
.personalInfoRowFour .btn{
   max-width:150px;
   margin:0 5px;
}
.personalInfoRowFour .btn-dark:hover{
    background-color:dimgray !important;
}
.personalInfoRowFour .btn-outline-light{
    color:black !important;
}
/* address */
#addressSave,#addressCancel,#addressPenBlue,#educationalSave,#educationalCancel,
#educationalPenBlue,#JobSave,#JobCancel,#JobPenBlue{
    display:none;
}
#addressPen,#educationalPen,#JobPen{
    cursor:pointer;
}
.AddressColLeft{
    display:flex;
    flex-wrap:wrap;
    align-content:center;
    padding:0px;
    align-items:center;
    justify-content:space-between;
}
.AddressColLeft .input-placeholder:first-child{
    padding-inline-end:7px;
}
.AddressColLef label{
    color:transparent !important;
}
.educationalInfo{
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:flex-start;
    padding:0px;
}
.Level{
    color:#313131 !important;
}
.personalInfoRowFourLast{
    display:flex;
    justify-content:flex-start;
    flex-wrap:wrap;
    align-items:center;
}
.rmdp-input {
    border-radius:5px;
    height:30px !important;
    margin:1px 0;
    padding:2px 5px;
}
.PboxForthRowSendMail{
    display:flex;
    justify-content:center;
    margin-top:10px;
}
.myBlackBtn{
    color:#313131 !important;
}
#ChangePasswordSave{
    height:45px;
}
/* ************************************* */
@media only screen and (max-width:991.98px) {
    .proBox{
        display:none;
    }
    .proBox.mob_proBox{
        display:flex !important;
        margin:0 0 20px 0;
        height:auto;
    }
    .input-placeholder,.rowTwoColOne, .personalInfoRowThree, .rowTwoColTwo, .rowTwoColThree{
        padding:0;
    }
    #MyJobUploadplaceholder{
        width:calc(100% - 3px);
    }
    .AddressColLeft .input-placeholder:first-child{
        padding-inline-end:15px;
    }
}
@media only screen and (max-width:599px) {
    .personalInfoRowOne{
        padding:20px 0px;
    }
    #myDivDash{
        padding:0px;
    }
    .Cus2PersonalInfo p, .Cus2PersonalInfoMidline p{
        font-size:18px !important;
    }
}