@import url('./LeftNavBar/LeftNavBar.css');
@import url('./TopMenu/TopMenu.css');
@import url('./Customers/Customers.css');
@import url('./Customers/agentCus/Customers.css');
@import url('./Agents/Agents.css');
@import url('./Prices/prices.css');
@import url('./LeftNavBar/customer1/cus1.css');
@import url('./LeftNavBar/customer2/cus2.css');
@import url('./LeftNavBar/customer3/cus3.css');
@import url('./LeftNavBar/customer4/cus4.css');
@import url('./LeftNavBar/customer5/cus5.css');
@import url('./LeftNavBar/customer6/cus6.css');
@import url('./LeftNavBar/customer7/cus7.css');
@import url('./LeftNavBar/customer8/cus8.css');
@import url('./LeftNavBar/customer9/cus9.css');
@import url('./Customers/agentAgent/Agents.css');
@import url('./Home//HomeLawyer/Home.css');
@import url('./Home//HomeCustomer/Home.css');
@import url('./Home/HomeMarketer/Home.css');
@import url('./LeftNavBar/customer1-1/cus1.css');
@import url('./LeftNavBar/customer1-2/cus1.css');
@import url('./LeftNavBar/customer1-1-1/prices.css');
@import url('./LeftNavBar/customer1-3/cus1.css');
@import url('./LeftNavBar/AplSelfInfoS1/AplSelfInfoS1.css');
@import url('./LeftNavBar/tahsilChoosePage/tahsilChoosPage.css');
@import url('./LeftNavBar/ZabanInfo/ZabanInfo.css');
@import url('./LeftNavBar/TahsilInfo/TahsilInfo.css');
@import url('./LeftNavBar/SoalVisa/SoalVisa.css');
@import url('./Message//Message.css');
@import url('./Customers/agentAgent/Agents.css');
@import url('./Home//HomeAgent/Home.css');
::-webkit-scrollbar{
    width:0;  /* Remove scrollbar space */
    background:transparent;  /* Optional:just make scrollbar invisible */
}
::-webkit-scrollbar-thumb{
    background:#ff000000;
}
*{
    scrollbar-width: thin;
    scrollbar-color: #D4D4D4 #ececec;
}
/* Works on Chrome,Edge,and Safari */
*::-webkit-scrollbar{
	width:9px;
}
*::-webkit-scrollbar-track{
	background:#ececec;
}
*::-webkit-scrollbar-thumb{
	background-color:#D4D4D4 ;
	border-radius:20px;
	border:0;
}
img{
    max-width:100%;
}
.mainrow{
    --bs-gutter-x:0 !important;
    background-color:#f6f6f6;
    display:flex;
    justify-content:space-evenly;
    padding:20px;
    align-items:stretch;
}
.dashTop{
    display:flex;
    padding:15px 0 0 0;
    padding-inline-start:30px;
    flex-wrap:wrap;
    justify-content:space-around;
}
#myDivDash{
    padding:10px 15px 0 15px;
    padding-inline-start:30px;
}
.myRowDashMob{
    display:flex;
    padding:0;
}
.APADING{
    color:gray !important;
}
.box_info_main{
    margin-bottom:15px;
}
#myServiceBtn:hover,.setupNew .btn-dark:hover{
    color:black;
}
@media only screen and (max-width:1230.98px) and (min-width:992px){
    .mainrow{
        padding:10px;
    }
}
@media only screen and (max-width:767.98px){
    .mainrow{
        align-items:center;
        padding:0px;
    }
    .LeftNavBar{
        display:none;
    }
    .dashTop{
        margin-top:0px;
        margin-inline-start:0%;
        background: url('images/left.png') center no-repeat;
        padding:0;
        padding: 0;
        position: fixed;
        z-index: 999;
    }
    .myRowDashMob{
        padding:0;
    }
    #myDivDash{
        padding:15px !important;
        margin-top:105px;
    }
    .aNonDecorM{
        color:#000000 !important;
        text-decoration:none !important;
        font-family:NunitoSans;
        display:none;
    }
    .menu_mobile{
        position:absolute;
        margin:0;
        top:0;
        height:100vh;
        background:#fff;
        padding:0 0 10px 0;
        border:1px solid #999;
        overflow-y:scroll;
    }
    .menu_mobile:lang(en){
        width:60%;
        left:0;
        border-radius:0 31px 31px 0;
    }
    .menu_mobile:lang(fa){
        width:60%;
        right:0;   
        border-radius:31px 0 0 31px;
    }
    .PboxThirdRow p{
        margin-inline-start:44px;
        color:#313131 !important;
        padding:20px 0px;
    }
    .box{
        background-color:rgb(255 255 255);
        padding:30px 0 50px;
        display:flex;
        flex-wrap:wrap;
        align-items:flex-start;
        -webkit-backdrop-filter:none;
        backdrop-filter:none;
        border-radius:0;
    }
    .close_navbar{
        display:flex !important;
        position: absolute;
        cursor:pointer;
    }
    .close_navbar:lang(en){
        right: 5%;
        top: 5%;
        z-index: 9999;
    }
    .close_navbar:lang(fa){
        left:5%;
        top: 5%;
        z-index: 9999;
        transform: rotate(180deg);
    }
    .box_info_main{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
    }
    .iconsbox{
        align-items:flex-start;
        width:80%;
        padding-left:0;
    }
    .mainstyle{
        /* background-image:url(/static/media/left.5d435506.png), linear-gradient(rgb(219, 166, 166), rgb(0, 0, 172)); */
        background-position:center center;
        background-repeat:no-repeat;
        background-size:cover;
        border-radius:10px;
        padding:0px;
    }
    .personalInfoRowTwoTwo .CustomerLine,.mynewRowCusStep111{
        display:none;
    }
    .mainRowProPrice2{
        height:unset;
    }
 
}
@media only screen and (max-width:500.98px){
    #myDivDash{
        padding:12px !important;
    }
    .menu_mobile:lang(en){
        width:90%;
        left:0;
    }
    .menu_mobile:lang(fa){
        width:90%;
        right:0;   
    }
}