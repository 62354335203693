@import url('./profile/profile.css');
#searchbox{
    padding:0;
    margin:0;
    z-index:900;
}
.SerachBox{
    box-shadow:hsl(0deg 0% 80%) 0 0 16px;
    background-color:#fff;
    border-radius:4px;
    padding:10px;
    display:none;
    flex-direction:column;
    flex-wrap:nowrap;
    align-content:center;
    justify-content:center;
    align-items:center;
    width:61.5%;
    position:absolute;
    top:11%;
}
.SerachBox:lang(en) {
    left:4.5%;
}
.SerachBox:lang(fa) {
    right:4.5%;
}
#searchbox svg{
    position:absolute;
    top:28%;
}
#searchbox svg:lang(en) {
    left:3%;
}
#searchbox svg:lang(fa) {
    right:3%;
}
#searchbox label{
    color:#f6f6f600;
}
.SerachBox input {
    border:1px solid #ffffff !important;
}
input#inputSuccess2::placeholder{
    color:#080808 !important;
}
input#inputSuccess2{
    height:75px;
    border:1px solid #c6c6c6 !important;
}
input#inputSuccess2.form-control{
    padding-inline-start:15px;
}
input#inputSuccess2:hover {
    cursor:pointer;
}
.form-control:focus {
    color:#212529;
    background-color:#fff;
    border-color:transparent;
    outline:0;
    box-shadow:none;
}
.tpMenu{
    padding:0 7px;
}
.tpHelp{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    padding:0;
}
.messageBox{
    box-shadow:hsl(0deg 0% 80%) 0 0 16px;
    background-color:#fff;
    border-radius:4px;
    padding:13px 10px;
    display:none;
    flex-direction:column;
    align-items:center;
    position:absolute;
    width:200px;
    position:absolute;
    z-index:1000;
    top:25px;
}
#messageBox1>.aNonDecor:last-child{
    margin:0;
}
.messageBox2{
    padding:17px 0 0 0 !important;
    box-shadow:hsl(0deg 0% 80%) 0 0 16px;
    background-color:#fff;
    border-radius:4px;
    display:none;
    width:120px;
    height:129px;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    position:absolute;
    z-index:199;
}
.messageBox2 button,.messageBox2 a{
    position:relative;
    padding-inline-start:13px !important;
}
.messageBox2 button::before,.messageBox2 a::before{
   content:"";
   width:3px;
   height:3px;
   border-radius:50%;
   background-color:black;
   position:absolute;
   top:9px;
   margin-inline-end:5px;
   display:inline-block;
}
.messageBox2 button:lang(en)::before,.messageBox2 :lang(en)::before{
    left:7px;
}
.messageBox2 button:lang(fa)::before,.messageBox2 :lang(fa)::before{
    right:7px;
}
.messageBox:lang(en){
    right:5%;
}
.messageBox:lang(fa){
    left:5%;
}
.messageBox p{
    font-size:10px;
    color:rgb(0, 0, 0) !important;
    display:block;
}
.messageBox a {
    color:rgb(0, 0, 255) !important;
}
.aNonDecor {
    color:#000000 !important;
    text-decoration:none !important;
    font-family:NunitoSans;
    display:flex;
}
.avatarStyle{
    display:flex;
    align-content:center;
    align-items:center;
    padding-inline-end:0;
    padding-inline-start:10px;
    position:relative;
    cursor:pointer;
    z-index:9999;
}
.avatarStyle img{
    max-width:100%;
}
.msg2Box{
    position:absolute;
    bottom:0;
    z-index:19999;
}
.msg2Box:lang(en){
    left:-40%;
}
.msg2Box:lang(fa){
    right:-40%;
}
.messageBox2 a{
    font-size:14px !important;
    color:black !important;
    padding-inline-start:10px;
}
#leftNavMobileMenu{
    visibility: hidden;
}
.messageBox2 button{
    border-color:transparent;
    background-color:transparent !important;
    font-size:12px;
    color:#000000 !important;
    padding-inline-start:8px;
}
.img_avatarStyle{
    width:42px;
    height:42px;
    border-radius:50%;
    overflow:hidden;
    margin-inline-end:10px;
}
.img_avatarStyle img{
    width:42px;
    height:42px;
    border-radius:50%;
}
.help_center{
    padding:0 5px;
}
.notification{
    display:flex; 
    padding:0;
    justify-content:space-evenly
}
.notif_center{
    position:relative;
}
.notif_center>span>b{
    color:white;
    position:absolute;
    font-size:12px;
    top:-5px;
    background:red;
    border-radius:50%;
    width:18px;
    display:flex;
    align-items:center;
    justify-content:center;
}
/* .star_dsplus{
    position:absolute;
    top:-5px;
    border:1px solid #959177;
    border-radius:50%;
    background-color:#fff;
    padding:3px;
}
.star_dsplus:lang(en){
   left:0;
}
.star_dsplus:lang(fa){
   right:0;
} */
.notif_center>span>b:lang(en){
    left:-5px;
}
.notif_center>span>b:lang(fa){
    right:-5px;
}
.LineSearch{
    display:flex;
    color:black;
    width:20%;
    margin:12px;
}
.messageBox2::after,.messageBox::after{
    content:"";
    background-color:blue;
    height:3px;
    width:80%;
    margin:0 auto;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    border-radius:5px;
}
a.disabled {
    pointer-events:none;
    cursor:default;
    text-decoration:none;
    font-size:13px !important;
}
.PboxWallet{
    position:relative;
    width:50%;
    height:70vh;
    top:calc(50% - 35vh);
    background:#cfcfcf;
    border-radius:18px;
    padding:20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.PboxWallet:lang(en){
    left:25%;
}
.PboxWallet:lang(fa){
    right:25%;
}
.Pbox_Heplcenter{
    position:relative;
    width:95%;
    height:90vh;
    top:calc(50% - 45vh);
    background:#f6f6f6;
    border-radius:18px;
    padding:30px 20px !important;
}
.Pbox_Heplcenter:lang(en){
    left:2.5%;
}
.Pbox_Heplcenter:lang(fa){
    right:2.5%;
}
.Pbox_Heplcenter .body_homecenter{
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
}
.head_helpcenter{
    height:50px;
    border-bottom:1px solid #d3a281;
}
.head_helpcenter h4{
    font-size:23px !important;
    color:#313131 !important;
    font-weight:700 !important;
}
.Pbox_Heplcenter .total_services{
    height:calc(100% - 80px);
    overflow-y:auto;
}
/* .Pbox_Heplcenter .login-btnStep6{
    margin:10px auto 0 auto;
    max-width:250px;
} */
.finalAdecor{
    background-color:aliceblue;
    text-decoration:unset;
    cursor:inherit;
    margin-inline-start:5px;
    padding:0 3px;
    border-radius:3px;
}
.teleLogocom{
    height:40px;
    position:absolute;
    top:25px;
}
.Social_comsoon{
    height:25px;
    position:absolute;
    bottom:15px;
}
.teleLogocom:lang(en),.Social_comsoon:lang(en){
    left:15px
}
.teleLogocom:lang(fa),.Social_comsoon:lang(fa){
    right:15px
}
.teleLogocom img{
    max-height:40px;
    max-width:100%;
}
.maintxt_comsoon{
    height:35px;
    display:flex;
    justify-content:center;
    margin-bottom:10px;
}
.maintxt_comsoon img{
    max-height:35px;
    max-width:100%;
}
.txt_comsoonP{
    margin:15px auto;
    text-align:center;
    color:black !important;
    font-size:27px !important;
}
.PboxWallet .myshapes{
    padding:10px;
}
.PboxWallet h6,.PboxWallet p,.PboxWallet h4{
    color:black !important;
}
.PboxWallet .subRegisterrightheader h4::before{
    background-color:black;
}
@media only screen and (min-width:1439.98px){
    .PboxWallet{
        padding:30px;
        height:auto;
        top:calc(50% - 25vh);
    }
}
@media only screen and (max-width:991.98px){
    .notification,.arrow_avatarStyle{
        position:relative;
        z-index:9999;
    }
    .notification svg,.help_center svg{
        width:20px;
    }
    .arrow_avatarStyle svg{
        width:15px;
    }
    .img_avatarStyle{
        width:35px;
        height:35px;
        display:flex;
        align-items:center;
    }
    .img_avatarStyle img{
        max-height:35px;
    }
    .PboxWallet{
        width:90%;
        left:5%;
        top:calc(50% - 35vh);
        justify-content:space-evenly;
    }
}
@media only screen and (max-width:767.98px){
    .tpMenu{
        padding:15px;
        position:relative;
        z-index:90;
    }
    .tpHelp{
        position:absolute;
        top:35px;
    }
    .tpHelp:lang(en){
        right:15px;
    }
    .tpHelp:lang(fa){
        left:15px;
    }
    input#inputSuccess2{
        border:1px solid #ffffff !important;
        border-radius:33px !important;
        line-height:3;
    }
    input#inputSuccess2.form-control{
        padding-inline-start:51px;
    }
    #searchbox label{
        color:#f6f6f600;
    }
    #searchbox svg{
        position:absolute;
        top:35%;
        cursor:pointer;
    }
    #searchbox svg:lang(en){ 
        left:3%;
    }
    #searchbox svg:lang(fa){
        right:3%; 
    }
    .avatarStyle{
        padding-inline-start:5px;
    }
    .msg2Box:lang(en){
        left:unset;
        right:0;
    }
    .msg2Box:lang(fa){
        right:unset;
        left:0;
    }
    .messageBox2:lang(en){
        top:77%;
        right:0%;
        left:unset;
    }
    .messageBox2:lang(fa){
        top:77%;
        left:0%;
        right:unset;
    }
    .messageBox2 a{
        font-size:12px;
        color:black !important;
        padding-inline-start:10px;
    }
}
@media only screen and (max-width:400.98px){
    .tpMenu{
        padding:8px;
    }
    .tpHelp{
        top:29px;
      }
}