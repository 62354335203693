.css-e363es-MuiFormControl-root{
  min-width: 47% !important;
  width: 47%;
}
.DropDownStyleRegStep3{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10.3%;
}
option {
  color: rgb(0, 0, 0) !important;
  background: #ffffff !important;
}
.form-select:focus{
  box-shadow: none !important;
}
.form-select:hover{
  cursor: pointer;
  border-color: black !important;
}
.myProCirlce3{
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: inline-block;
  background-color: #0a0a0a00;
  border-top: 4px solid transparent;
  border-left: 4px solid transparent;
  border-right: 4px solid #000000;
  border-bottom: 4px solid #000000;
  transform: rotate(-43deg);
}