@import url('./ResetPasswordS3/ResetPasword3.css');
@import url('../login/servicesLogin/ServicesLogin.css');
@import url('../login/AboutusLogin/AboutusLogin.css');
@import url('../login/CollaborationsLogin/CollaborationsLogin.css');
@import url('../login/ContactUsLogin/ContactUsLogin.css');
@import url('../login/HelpLogin/HelpLogin.css');
@import url('../login/TermsLogin/TermsLogin.css');
/* left column styles */
.content{
  position:relative;
}
.pagePic{
  background:#dbbebe url("./images/login2.png") no-repeat;
  padding:20px 0;
  width:100%;
  height:100%;
  background-size:cover;
  z-index:-1;
  filter:blur(0px);
  -webkit-filter:blur(0px);
  position:absolute;
  bottom:0;
  top:0;
  left:0;
  right:0;
}
.inqueyLine{
  margin-top:35px;
}
.inqueyLine p{
  color:#fbfbfbcf !important;
}
.loginMainRow{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  padding:35px 0;
  --bs-gutter-x:0;
  position:relative;
  min-height:100vh;
}
.sectionsLogin,.leftSection{
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
}
.leftSection .leftSectionTopGrup{
  display:flex;
  flex-direction:column;
  justify-content:space-evenly;
  padding:0;
}
.rectangle{
  background-color:#775d5475 !important;
  /* height:calc(100% - 70px); */
  padding:35px 60px 15px 60px;
  border-radius:25px;
}
.rectangle .rightSection{
  display:flex;
  justify-content:center;
  align-items:center;
}
.rightSection img{
  max-height:100%;
  max-width:100%;
  /* border-radius:16px; */
}
.form-control{
  border-radius:8px !important;
}
.rectangle h4{
  font-family:'NunitoSansBold';
}
.loginMainRow button,a{
  font-size:15px !important;
}
.form-control:focus{
  box-shadow:none !important;
}
.input-placeholder{
  position:relative;
}
.loginMainRow .input-placeholder{
  margin:0 !important;
}
.input-placeholder input{
  padding:2px;
}
.loginMainRow .input-placeholder input,.loginMainRow .input-placeholder select,.loginMainRow .input-placeholder textarea{
  padding-inline-start:41px;
  background-color:transparent;
  color:white;
  border-color:#d5d5d53b !important;
  margin-bottom:7px;
  line-height:1.85 !important;
  height:40px !important;
  border-radius:5px !important;
  font-size:14px !important;
}
.loginMainRow .input-placeholder input:hover{
  border-color:#313131 !important;
}
.loginMainRow .input-placeholder input::placeholder{
  color:#fff !important;
}
.input-placeholder svg:lang(en){
  position:absolute;
  top:11px;
  left:16px;
}
.input-placeholder svg:lang(fa){
  position:absolute;
  top:11px;
  right:16px;
}
.input-placeholder label{
  color:black !important;
  font-size:12px;
  margin-bottom:0px;
  margin-top:0px;
  display:none;
}
.input-placeholder input:hover{
  cursor:pointer !important;
}
input::-ms-reveal,input::-ms-clear{
  display:none;
}
#eye{
  position:absolute;
  top:calc(50% - 9px);
  cursor:pointer;
}
#eye:lang(en){
  left:89%; 
}
#eye:lang(fa){
  right:89%;
}
#eye:hover path,#eyeslash:hover path{
  stroke:black !important;
}
#eyeslash{
  position:absolute;
  top:calc(50% - 9px);
  display:none;
  cursor:pointer;
}
#eyeslash:lang(en){
    left:89%;
}
#eyeslash:lang(fa){
    right:89%;
}
.input-placeholder input:focus + .placeholder span,.input-placeholder textarea:focus + .placeholder,.input-placeholder input:focus + .placeholderPro span,
.input-placeholder input:focus + .placeholderPro,.input-placeholder textarea:focus + .placeholderPro{
  color:transparent !important;
}
.placeholder{
  position:absolute;
  pointer-events:none;
  height:40px;
  top:0;
  bottom:0;
  background-color:transparent;
  padding-inline-start:25px;
  opacity:1 !important;
  display:flex;
  align-items:center;
}
.input-placeholder:hover path{
  stroke:#313131 !important;
}
.placeholder:lang(en){
  left:16px;
}
.placeholder:lang(fa){
  right:16px;
}
.form-select:lang(en){
  background-position:right 0.75rem center;
}
.form-select:lang(fa){
  background-position:left 0.75rem center;
}
.form-select{
 background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
.placeholder span{
  color:red;
}
#errorLabelEmail{
  display:none;
  position:relative;
  padding-inline-start:25px;
  margin-bottom:5px;
}
#errorLabelEmail svg{
  top:1px;
}
#errorLabelEmail svg:lang(en){
  left:0px;
}
#errorLabelEmail svg:lang(fa){
  right:0;
}
.remember-forget{
  display:flex;
  align-items:center;
  justify-content:space-between;
}
input[type="checkbox"]#rememberMe{
  filter:none;
  accent-color:transparent;
}
.remember-forget a{
  font-size:14px !important;
  text-decoration:none !important;
}
.remember{
  display:flex;
  align-content:center;
  flex-wrap:nowrap;
  align-items:center;
}
.remember label{
  font-size:14px;
  font-family:'NunitoSans';
  padding-inline-start:5px;
  margin:0px !important;
}
.login-btn{
  width:100%;
  text-align-last:center;
}
.btn-dark:hover{
  color:#313131;
  background-color:#e3e7ed45;
  border-color:#e3e7ed45;
}
.signup-btn{
  width:100%;
  text-align-last:center;
  font-size:14px !important;
}
.line-or{
  display:flex;
  justify-content:center;
  color:white;
  font-family:'Nunito Sans';
  font-size:80%;
  align-items:baseline;
}
.copyright-text{
  display:flex;
  justify-content:center;
  color:white;
}
.copyright-text p{
  color:white;
}
.rectangle_bottom{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  margin-top:20px;
}
.rectangle_bottom .css-b62m3t-container{
  max-width:fit-content !important;
}
.CreatableSelectLogin{
  display:flex;
  justify-content:flex-end;
  padding-inline-end:0;
}
.about-us{
  display:flex;
  align-items:center;
  padding-inline-start:3px;
}
.about-us span{
  font-size:12px !important;
}
.about-us p{
  font-size:12px !important;
  margin-bottom:0px !important;
}
.flags{
  text-align:center;
  display:flow-root;
  direction:ltr;
}
.handleIR{
  margin-left:0px;
  margin-bottom:-10px;
  color:#313131 !important;
}
.handleIR:lang(fa){
  color:#9b9b9b !important;
  font-family:'NunitoSans' !important;
}
.handleUS:lang(en){
  margin-left:0px;
  color:#9b9b9b !important;
}
.handleUS:lang(fa){
  margin-left:0px;
  color:#313131 !important;
  font-family:'NunitoSans' !important;
}
.footer-copy,.footer-copy2{
  display:flex;
  justify-content:center;
  color:#646464 !important;
  font-family:'NunitoSansSemiBold';
  font-size:10px !important;
  align-items:baseline;
  margin-top:0px;
  text-decoration:none !important;
}
.footer-copy2{
  display:none;
  color:#313131 !important;
  font-family:'NunitoSansSemiBold';
  margin-top:-11px;
  text-align:center;
}
.myBoldFont{
  font-size:23px !important;
  position:relative;
  margin-bottom:15px;
}
.myBoldFont:lang(en){
  font-family:'NunitoSansBold' !important;
}
.myBoldFont:lang(fa){
  font-family:'MorabbaBlack' !important;
}
.myBoldFont::before{
  content:"";
  width:35px;
  height:1px;
  background-color:#fff;
  position:absolute;
  bottom:-10px;
}
.myBoldFont:lang(fa){
  right:0;
}
.myBoldFont:lang(en){
  left:0;
}
.faEnPLogin{
  font-size:67%;
  padding-top:0px !important;
  color:black !important;
  display:flex;
  flex-direction:column;
}
.faEnPLogin a{
	color:#000000 !important;
  text-decoration:none !important;
}
.forgetPassword{
  margin:0px;
}
.forgetPassword a:hover{
  color:#313131;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow:0 0 0 30px #b5a69e inset !important;
}
.LangBox{
  background-color:white;
  color:black;
  width:23px;
  display:none;
  align-items:center;
  flex-direction:column;
  top:-25px;
  right:15px;
  box-shadow:hsl(0deg 0% 2%) 0 0 10px;
  border-radius:26px;
  z-index:9999;
  padding:0px;
  padding-top:2px;
  padding-bottom:0px;
  font-size:12px !important;
  z-index:99;
  border-right-color:#313131;
  margin-inline-end:5px;
}
.LangBox a{
  font-size:12px !important;
  color:#313131 ;
  text-decoration:none;
}
.LangBox hr{
  color:#313131 !important;
  padding:0px !important;
  border-color:#313131 !important;
  background-color:#313131 !important;
  width:20px;
  margin-bottom:5px;;
}
.footerDasilav{
  position:absolute;
  height:30px;
  bottom:10px;
  display:flex;
  justify-content:center;
  align-items:flex-end;
}
input[type="checkbox"]{ /* change "blue" browser chrome to yellow */
  filter:brightness(50%) sepia(1);
}
input:valid{
  border-color:#313131 !important;
}
/* mobile */
.LogoMobile{
  height:80px;
  display:none;
  justify-content:center;
  align-items:center;
  margin-bottom:65px;
}
.LogoMobile img{
  max-height:80px;
}
.spanAbout a{
  text-decoration:none;
  font-size:14px !important;
  padding:0 2px;
}
.PboxThirdRowLogin{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  align-content:center;
}
.PboxThirdRowLogin p{
  color:#313131 !important;
  padding:0px 0px;
  padding-top:5px;
}
.line-or p{
  margin-bottom:0rem;
}
.css-uwgul2-control:hover{
  border-color:rgba(255, 255, 255, 0) !important;
}
.css-uwgul2-control{
  border-color:rgba(255, 255, 255, 0) !important;
  box-shadow:0 0 0 1px #fbfbfc00 !important;
}
.css-1gtu0rj-indicatorContainer{
  color:transparent !important;
}
.css-tlfecz-indicatorContainer svg{
  color:#313131;
}
.css-dgo6x6-control{
  margin-top:0 !important;
}
.rectangle_bottom #SelectLanguage,.LeftNavBar #SelectLanguage,.menu_mobile #SelectLanguage,.footer_page #SelectLanguage{
  border:none !important;
  height:unset !important;
  margin:0 !important;
  padding:5px 0 !important;
  min-width:70px;
  max-width:85px;
  margin: 3px auto 0 auto !important;
}
.footer_page #SelectLanguage{
  color:#515050 !important;
  font-size:12px;
}
.rectangle_bottom #SelectLanguage{
  color:#fff !important;
  font-size:12px;
}
.form-select:disabled {
  background-color:#efeae9;
}
@media only screen and (min-width:1439.98px){
  .rectangle{
    height:calc(100vh - 100px);
  }
  .inqueyLine{
    margin-top:80px;
  }
  .rectangle_bottom{
    margin-top:30px;
  }
  @media only screen and (max-height:968.98px){
    .rectangle{
      height:auto;
    }
  }
}
@media only screen and (min-width:992px) and (max-width:1199.98px){
  .loginMainRow{
    padding:50px 100px;
  }
}
@media only screen and (min-width:768px) and (max-width:991.98px){
  .loginMainRow{
    padding:50px 50px;
  }
}
@media only screen and (max-width:1199.98px){
    body{
      background-color:#fff !important;
    }
   .pagePic{
     padding:0;
    }
   .footer-copy,.rightSection{
     display:none !important;
    }
    .LogoMobile,.footer-copy2{
      display:flex;
    }
    .myBoldFont{
       margin-bottom:50px;
    }
}
@media only screen and (max-width:991.98px){
  .CreatableSelectLogin,.about-us,.rectangle_bottom .inputAndlabel{
    justify-content:center;
  }
  .rectangle_bottom{
    max-height:80px;
  }
  .pagePic{
    background-position:center;
    background-color:#cbaeae;
    /* background-image:none;
    background-color:#afafaf;
    background:#AFAFAF;
    filter:NONE;
    -webkit-filter:none; */
  } 
  input:autofill,input:autofill:hover,input:autofill:focus,input:autofill:active{
    -webkit-box-shadow: 0 0 0 30px #ddc7bc inset !important;
  }  
  #eye:lang(en){
    left:93%;
  }
  #eye:lang(fa){
    right:93%;
  }
  .rectangle_bottom #SelectLanguage,.footer_page #SelectLanguage{
    margin: 11px auto 0 auto !important;
  }
}
@media only screen and (max-width:767.98px){
  .loginMainRow{
    padding:0;
  }
  .rectangle{
    border-radius:0 !important;
    padding-bottom:60px !important;
    background-color:#74767600 !important;
  }
  .rectangle{
    height:unset !important;
  } 
}
@media only screen and (max-width:600px){
  .rectangle{
    padding:35px 20px 10px 20px;
    background-color:#74767600 !important;
  }
  .rectangle_bottom{
    padding:0 5px;
  }
  .leftSectionTopGrup,.about-us{
    padding:0;
  } 
  .about-us p{
     display:flex;
  }
  .about-us a{
    text-align:center;
  }
  #eye:lang(en){
    left:91%;
  }
  #eye:lang(fa){
    right:91%;
  }
}
@media only screen and (max-width:500px){
  .rectangle_bottom #SelectLanguage,.footer_page #SelectLanguage{
    border:1px solid #fff !important;
    border-radius:5px !important;
    padding:5px !important;
    min-width:90px !important;
  }
  .footer_page #SelectLanguage{
    border:1px solid #515050 !important;
  }
}
@media only screen 
  and (min-device-width:414px) 
  and (max-device-width:736px) 
  and (-webkit-min-device-pixel-ratio:3) { 
    .pagePic{
      background-position:center;
      background-color:#cbaeae;
  }
}