@import url('./components/login/Login.css');
@import url('./components/login/ResetPasword/ResetPasword.css');
@import url('./components/login/ResetPasswordS2/ResetPasword2.css');
/* @import url('./components/login/ResetPasswordS3/ResetPasword3.css'); */
@import url('./components/Register/Register.css');
@import url('./components/Register/regStep2/Register.css');
@import url('./components/Register/regStep3/Register.css');
@import url('./components/Register/regStep4/Register.css');
@import url('./components/Register/regStep5/Register.css');
@import url('./Dashboard/Dashboard.css');
@import url('./404/404.css');

@font-face {
    font-family: "MorabbaBlack";
    src: local("MorabbaBlack"),
     url("./fonts/morabba/Morabba-Medium.woff2") format("truetype");
    font-weight:normal;
    }
@font-face {
    font-family: "NunitoSans";
    src: local("NunitoSans"),
        url("./fonts/NunitoSans/NunitoSans-Regular.ttf") format("truetype");
    font-weight:normal;
}
@font-face {
    font-family: "NunitoSansBold";
    src: local("NunitoSans"),
        url("./fonts/NunitoSans/NunitoSans-ExtraBold.ttf") format("truetype");
    font-weight:normal;
}
@font-face {
    font-family: "NunitoSansSemiBold";
    src: local("NunitoSansSemiBold"),
        url("./fonts/NunitoSans/NunitoSans-SemiBold.ttf") format("truetype");
    font-weight:normal;
}
.font-face-gm :lang(fa){
    font-family: "MorabbaBlack";
}
.font-face-gm :lang(en){
    font-family: "NunitoSans";
}
body:lang(en), ul:lang(en), li:lang(en), h1:lang(en), h2:lang(en), h3:lang(en), h4:lang(en),
 h5:lang(en), h6:lang(en), a:lang(en) ,p:lang(en) ,label:lang(en) ,button:lang(en){
    font-family: NunitoSans;
    color: white;
    font-size: 14px;
}
body:lang(fa), ul:lang(fa), li:lang(fa), h1:lang(fa), h2:lang(fa), h3:lang(fa), h4:lang(fa),
 h5:lang(fa), h6:lang(fa), a:lang(fa) ,p:lang(fa), label:lang(fa) ,button:lang(fa){
    font-family: MorabbaBlack;
    color: white;
    font-size: 14px;
}
@media only screen  and (max-device-width: 667px)
{
    body{
        background-color: #9e6b6b;
    }
}
