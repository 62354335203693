.myForm{
  margin-top:20px;
}
.myForm form{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
}
#inputDate{
  padding:0 10px;
  background-color:transparent;
  color:white ;
  border-color:#5a59593b;
  margin-bottom:7px;
  line-height:1.85 !important;
  width:100%;
}
.myForm input{
  padding:0 10px !important;
  margin-bottom:0 !important;
}
.myForm .input-placeholder{
  margin-bottom:10px !important;
  padding:0 7px;
}
.myForm .placeholder{
  padding:0 !important;
}
.myForm .placeholder:lang(en){
   left:20px;
}
.myForm .placeholder:lang(fa){
  right:20px;
}
.loginMainRow .input-placeholder textarea{
  height:auto !important;
  padding:0 10px !important;
}
.loginMainRow .input-placeholder select{
  padding-inline-start:9px !important;
}
.myForm .btn-outline-light:hover{
  background-color:transparent !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance:none;
  margin:0;
}
/* Firefox */
input[type=number]{
  -moz-appearance:textfield;
}
.myProCirlce2{
  position:absolute;
  border-radius:50%;
  width:100px;
  height:100px;
  display:inline-block;
  background-color:#0a0a0a00;
  border-top:3px solid transparent;
  border-left:3px solid transparent;
  border-right:4px solid black;
  border-bottom:3px solid #00000000;
  transform:rotate(-31deg);
}
.inputRegister .css-r47a1p-MuiFormControl-root{
 margin:3px !important;
}
.inputRegister .css-12yjm75-MuiInputBase-input-MuiOutlinedInput-input{
  height:00.8125rem;
}
.NextStep{
  display:flex;
  justify-content:flex-end;
  margin:30px 0 20px;
}
.NextStep .btn{
  width:140px;
  justify-content: center;
}
.NextStep .btn-outline-light{
  border-color:transparent !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding:3.45px 5px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color:#ffffff80 !important;
  border-width:1px !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  overflow-y:overlay !important;
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  font-family:'IRANSansBlack' !important;
  color:rgb(255 255 255 / 88%) !important;
  left:0px !important;
  top:-12px !important;
  font-size:10px !important;
} */
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color:#1976d200 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root:lang(fa) {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: calc(100% - 42px) !important;
  top: calc(100% - 51px) !important;
  -webkit-transform: translate(14px, 16px) scale(1);
  -moz-transform: translate(14px, 16px) scale(1);
  -ms-transform: translate(14px, 16px) scale(1);
  transform: translate(14px, 16px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root:lang(en) {
  top: -3px !important;
}

.DropDownStyle{
  display:inline-flex;
  width:40%;
  flex-direction:row;
  flex-wrap:wrap;
  align-content:center;
  justify-content:center;
  align-items:center;
}
.DropDownStyle p{
    font-family:'IRANSansBlack';
    color:white;
    font-size:12px;
    display:inline-flex;
    flex-wrap:nowrap;
    flex-direction:row;
    align-content:center;
    justify-content:space-between;
}
.css-1dcsz64-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color:#00000080 !important;
  border-width:2px !important;
}
.css-vllyeh-MuiInputBase-input-MuiOutlinedInput-input{
  color:#fffffff7;
}
.css-vllyeh-MuiInputBase-input-MuiOutlinedInput-input:hover{
  color:#000000f7;
}
.css-1dcsz64-MuiInputBase-root-MuiOutlinedInput-root{
  height:13vh;
}
.rmdp-container{
  width:100%;
}
#errorLabelRegs{
  color:red;
  margin-top:-20px;
}
#MyPhoneplaceholder{
  display:flex;
}
.DateOfBirth{
  padding:0px;
  margin:0 !important;
  display:flex;
  align-items:center;
}
.placeholderD{
  position:absolute;
  pointer-events:none;
  top:5px;
  bottom:2px;
  margin:auto;
  background-color:transparent;
  padding-inline-start:25px;
  opacity:1 !important;
}
.placeholderD:lang(en){
  left:125px;
}
.placeholderD:lang(fa){
  right:125px;
}
.backtohomeM2{
  color:white !important;
}
@media only screen and (max-width:767.98px){
  .myForm{
    padding:0;
  }
  .NextStep{
    padding:0 7px;
    flex-direction:column-reverse;
  }
  .NextStep .btn{
    width:100%;
    margin:5px auto;
  }
  #errorLabelRegs{
    color:red;
    margin-top:20px;
  }
}