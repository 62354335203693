.personalInfo{
    padding:0;
}
.Cus2Step1SmallPBrown{
    color: #D3A281 !important;
}
.Step1e1 {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.Step1e1 a {
    color: black !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.reactangleCusG,.reactangleCus{
    padding:0 20px;
    margin:20px auto;
}
.box_reactangleCus{
    display:flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    min-height:163px;
    border:1px solid #c9c9c9;
    border-radius: 18px;
    cursor: pointer;
    padding:15px 20px 10px 20px;
    transition: height 0.6s ease;
}
.box_reactangleCus p{ 
    color:#797979 !important;
    font-size:18px !important;
    transition:all 0.5s ease;  
    margin-bottom:0; 
}
.CusReqCircle,.CusReqCircleG{
    display: flex;
    border-radius:50%;
    width:65px;
    height:65px;
    justify-content:center;
    align-items:center;
    transition: all 0.5s ease;
}
.CusReqCircle{  
    background-color: black;   
}
.CusReqCircleG{
    background-color: #D4D4D4;
}
.Cus2Inputs2 {
    display: flex;
    flex-wrap:wrap;
    margin-top:20px;
}
.progress{
    display:flex;
    height:38px !important;
    overflow: hidden;
    border-radius:13px;
    border:1px solid #e9ecef;
    width:90%;
    background-color:#fff;
    padding:0;
    color: #313131;
}
.progress-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #313131;
    text-align:center;
    white-space:nowrap;
    background-color: #65E14E !important;
    transition:width 0.6s ease;
    font-size:14px;
    height:36px;
    overflow:visible !important;
    padding:0 5px;
}
.progress-bar:lang(en){
    border-radius:12px 0 0 12px;
}
.progress-bar:lang(fa){
    border-radius:0 12px 12px 0;
}
.cusProgress{
    visibility: hidden;
    display:none;
}
.reactangleCus:hover .cusProgress{
    visibility:visible;
    display:block;
    transition:all 0.5s ease;
}
.reactangleCus:hover .CusReqCircle,.reactangleCus:hover .CusReqCircleG{
    margin-top:-60px;
    width:70px;
    height:70px;
    transition:all 0.5s ease;
}
.reactangleCus:hover p{
    margin-top: -10px;
    transition: all 0.5s ease;
}
@media only screen and (max-width: 1199.98px){
    .box_reactangleCus p{
        font-size:16px !important;
    }
}
@media only screen and (max-width: 991.98px){
    .reactangleCusG,.reactangleCus{
        padding:0 10px;
        margin:20px auto;
    }
}
@media only screen and (max-width: 600.98px){
    .reactangleCusG,.reactangleCus{
        width:100%;
        max-width:260px;
    }
}