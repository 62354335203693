
.form_addmyserv{
    margin-bottom:20px;
    padding-bottom:25px;
}
.CreatableSelect{
    margin-top:0px;
    padding-inline-start:0px;
}
.pricesInfo{
    color:#313131 !important;
    font-size:24px !important;
}
.css-qc6sy-singleValue{
    color:hsl(0, 0%, 20%);
    grid-area:1/1/2/3;
    margin-left:2px;
    margin-right:2px;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    box-sizing:border-box;
    font-size:14px !important;
}
.rowTwoColOnePrice{
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0px !important;
    padding-inline-start:14px;
    font-size:16px !important;
    justify-content:space-around;
    padding:0px;
}
.rowTwoColOnePrice label{
    color:black !important;
    display:flex !important;
    padding-bottom:0px;
    margin:0px !important;
    padding-inline-start:14px;
    font-size:14px !important;
}
.MyPaddingAlittle{
    margin-inline-end:2%;
    margin-inline-start:5%;
}
.rowTwoColOnePrice .placeholderPro{
    margin-top:37px;
    margin-inline-start:-10px;
    font-size:14px !important;
}
.rowTwoColOnePrice  input{
    border-color:#9b9b9b !important;
}
#proLastNamePrice,#Fee,#DCCommission,#ServiceNameBox,#ServiceCountryBox,#AboutService2{
    padding-inline-start:5px;
    background-color:transparent;
    color:#5b5b5b !important;
    border-color:#5a59593b;
    margin-bottom:9px;
    line-height:1.93 !important;
}
#AboutService{
    padding-inline-start:5px;
    background-color:transparent;
    color:#5b5b5b !important;
    border-color:#5a59593b;
    margin-bottom:10px;
    line-height:1.93 !important;
}
#MyAboutServiceRow .Cus2Inputs{
    padding:0;
}
#Fee:hover,#proLastNamePrice:hover,#AboutService:hover,#DCCommission:hover,#ServiceNameBox:hover,#ServiceCountryBox:hover,#AboutService2:hover{
    border-color:#313131 !important;
    cursor:pointer !important;
}
.mainRowProPrice{
    border-radius:20px;
    padding:0;
}
.mainRowProPrice2{
    height:328px;
    overflow-y:auto;
    scrollbar-color:transparent;
    border-radius:20px;
    padding:20px 15px 15px;
    margin-inline-start:1%;
    /* position:relative; */
}
.list_myserv{
    padding:20px 5px 15px !important;
    margin-inline-start:0 !important;
}
.top_headmyserv{
    display:flex;
    justify-content:flex-start;
    height:30px;
    border-bottom:1px solid #dadada;
    /* position:sticky;
    top:0;
    left:0;
    right:0;
    z-index:50;
    background-color:#f6f6f6; */
}
.top_headmyserv p{
    color:#313131 !important;
    padding:0 !important;
    -webkit-padding-start:10px;
    padding-inline-start:10px;
    margin-bottom:0;
}
.top_headmyserv>div{
    padding:0 5px;
}
.placeholderProPrice{
    position:absolute;
    pointer-events:none;
    top:7px;
    bottom:2px;
    margin:auto;
    color:#313131;
    background-color:transparent;
    padding-inline-start:25px;
    opacity:0.8 !important;
}
.MyAboutServiceRow{
    display:flex;
    flex-flow:column-reverse wrap;
    align-items:stretch;
    align-content:space-around;
}
.tariff_form{
   display:flex;
   flex-wrap:wrap;
   padding:0;
}
.personalInfoRowTwoTwo.tarifffform{
    padding:0;
}
#tariff_formID{
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap:nowrap;
}
.tariff_form input{
    height:45px;
    background-color:transparent;
    border:1px solid #707070;
    border-radius:5px;
}
.css-nqaof0-control{
    height:45px;
    background-color:transparent !important;
    border:1px solid #707070 !important;
    border-radius:5px;
    padding-inline-start:60px;
    position:relative;
    margin:0 !important;
    box-shadow:none !important;
}
.tariff_form #Service .css-1okebmr-indicatorSeparator{
    opacity:0;
}
.tariff_form .css-nqaof0-control>div,.css-6j8wv5-Input,.css-319lph-ValueContainer,.tariff_form .css-6j8wv5-Input,.tariff_form .css-1pndypt-Input{
    height:43px;
    margin:0 !important;
    padding:0 !important;
} 
.tariff_form .input-placeholder .placeholderPro{
    height:43px;
    display:flex;
    align-items:center;
    top:20px;
    margin:0;
}
.tariff_form .ReqiredCus{
    margin:5px 0 0 0;
}
.tariff_form .input-placeholder #proLastNamePrice{
    padding-inline-start:7px;
    padding-inline-end:50px;
}
.tariff_form .input-placeholder.price_servtariff{
    position:relative;
}
.assetsOfDoller{
    height:45px;
    display:flex;
    align-items:center;
    position:absolute;
    top:20px;
}
.assetsOfDoller:lang(en){
    right:20px;
}
.assetsOfDoller:lang(fa){
    left:20px;
}
.tariff_form .input-placeholder svg{
    position:unset;
    top:unset;
    right:unset;
    left:unset;
    color:#313131;
}
.tariff_form .input-placeholder.price_servtariff .assetsOfDoller::before{
    content:"| $";
    color:#313131;
    padding-inline-end:5px;
}
.tariff_form .input-placeholder.price_servtariff svg:lang(en){
    right:7px;
}
.tariff_form .input-placeholder.price_servtariff svg:lang(fa){
    left:7px;
}
.tariff_form .input-placeholder>label{
    display:inline-block;
}
.fee_servtariff>label{
    display:flex !important;
    justify-content:space-between;
}
.tariff_form .css-jxppsg-control{
    height:43px;
    background-color:transparent !important;
    border:1px solid #707070 !important;
    margin:0;
    padding:0 7px;
}
.css-nqaof0-control:hover{
    border-color:#313131 !important;
} 
.tariff_form .css-nqaof0-control::before{
    position:absolute;
    color:#313131;
}
.tariff_form .css-nqaof0-control:lang(en):before{
    content:"Other |";
    left:4px;
}
.tariff_form .css-nqaof0-control:lang(fa):before{
    content:"دیگر |";
    right:4px; 
}
.btn_servtariff{
    display:flex;
    align-items:center;
    padding:0;
}
.btn_servtariff button{
    height:45px;
    border-radius:5px;
    width:100%;
    margin-top:10px;
}
.item_listserv{
    display:flex;
    box-shadow:hsl(0deg 0% 80% / 22%) 0 0 16px;
    background-color:rgb(255, 255, 255);
    border-radius:12px;
    padding:10px 0;
    align-items:center;
    flex-wrap:wrap;
    margin-bottom:10px;
    position:relative;
    transition:all 0.3s ease-out;
}
.item_listserv .hidenRowAgent .hidenItem span{
    color:#666 !important;
    font-size:14px;
    padding-inline-end:10px;
    white-space:nowrap;
}
.item_listserv .MyAgentRowClick{
    min-height:50px;
    position:relative;
}
.item_listserv .hidenRowAgent .hidenItem:first-child{
    margin-bottom:0;
}
.mainRowProPrice2>div:nth-child(even) .item_listserv{
    background-color:#E8E8E8 !important;
}
.item_tariff{
    display:flex;
    box-shadow:hsl(0deg 0% 80% / 22%) 0 0 16px;
    background-color:rgb(255, 255, 255);
    border-radius:12px;
    padding:15px 10px;
    align-items:center;
    margin-bottom:10px;
    transition:all 0s ease-in-out;
    min-height:70px;
}
.item_tariff .agentCusRow{
    flex-wrap:wrap;
    position:relative;
}
.mainRowProPrice2>div:nth-child(even) .item_tariff{
    background-color:#E8E8E8 !important;
}
.item_tariff .agentCusRow>div{
    padding:0 5px;
}
.item_tariff p,.item_listserv p{
    color:#313131 !important;
    margin-bottom:0rem;
    font-size:12px !important;
}
.item_tariff span{
    display:none;
    color:#9B9B9B !important;
    font-size:14px;
    padding-bottom:10px;
}
.item_tariff input{
   background-color:transparent !important;
   border:1px solid #D4D4D4 !important;
   border-radius:3px;
   height:40px;
}
.AddPaddingEnd{
    padding-inline-end:0px;
}
.id_tariff{
    padding:0 5px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    position:relative;
}
.MyServiceAbout{
    padding:0px;
    margin-top:10px;
}
.priceInfoRowOne{
    display:flex;
    padding:8px 0px;
    justify-content:space-between;
}
.PenAndTrash{
    display:flex;
    justify-content:space-evenly !important;
}
.PriceListIcons{
    cursor:pointer;
}
.PriceInfoCircle:lang(en){
    position:absolute;
    top:-3px;
    right:0px;
}
.PriceInfoCircle:lang(fa){
    position:absolute;
    top:-3px;
    left:0px;
}
.otherBox{
    position:absolute;
    background-color:white;
    color:black;
    width:50px;
    display:none;
    align-items:center;
    flex-direction:column;
    top:-25px;
    box-shadow:hsl(0deg 0% 2%) 0 0 10px;
    border-radius:4px;
    z-index:99;
    padding:2px 10px;
    font-size:12px !important;
    z-index:99;
    border-right-color:#313131;
}
.otherBox:lang(en){
    right:15px;
}
.otherBox:lang(fa){
    left:15px;
}
.assetsOfDoller svg:lang(fa){
    position:absolute;
    top:8px;
    right:28px;
} 
.CreatableSelect p{
    margin-bottom:0px !important;
}
.saverdit_ms{
    display:none;
    background-color:#21d350;
    color:#fff;
    font-size:16px;
    justify-content:center;
    align-items:center;
    border-radius:5px;
    width:calc(100% - 10px);
    margin:0 auto;
}
.edit_tariffbtn{
    position:absolute;
}
.edit_tariffbtn:lang(fa){
    left:10px;
}
.edit_tariffbtn:lang(en){
    right:10px;
}
.input_editablesrv{
    padding:0 6px;
}
.input_editablesrv>input{
    border-radius:5px !important;
    box-shadow:none !important;
    border:1px solid #a6a6a6 !important;
    min-height:40px !important;
    display:flex;
    align-items:center;
}
.saverdit_ms:hover{
    cursor:pointer;
}
.saverdit_ms svg{
    padding-inline-end:5px;
}
.MyServiceAbout_btn{
    align-items:flex-end;
    margin-top:15px;
}
.ServiceAddBtn{
    height:48px;
}
textarea#AboutService2::placeholder,input#Fee::placeholder,input#proLastNamePrice::placeholder,textarea#AboutService::placeholder,input#DCCommission::placeholder,input#ServiceNameBox::placeholder,input,#ServiceCountryBox::placeholder{
    color:#212529 !important;
}
.tariff_form .inputAndlabel.DCCommission,.tariff_form .inputAndlabel.Fee,.Agentss_Fee{
    position:relative;
}
.tariff_form .inputAndlabel.Agentss_Fee::before,.tariff_form .inputAndlabel.DCCommission::before,.tariff_form .inputAndlabel.Fee::before{
    content:"%";
    position:absolute;
    top:0;
    right:15px;
    height:calc(100% - 7px);
    width:15px;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    color:#313131;
}
.tariff_form #DCCommission:lang(fa),.tariff_form #Fee:lang(fa),.tariff_form .Agentss_Fee>input:lang(fa){
    padding-inline-start:23px;
}
.tariff_form #DCCommission:lang(en),.tariff_form #Fee:lang(en),.tariff_form .Agentss_Fee>input:lang(en){
    padding-inline-end:23px;
}
@media only screen and (min-width:1439.98px){
    .mainRowProPrice2{
        height:700px;
    }
}
@media only screen and (min-width:992px) and (max-width:1280.98px){
    .btn_servtariff button{
    margin-top:-4px;
    }
}
@media only screen and (max-width:991.98px){
    .id_tariff,.price_tariff,.edit_tariffbtn{
        margin-bottom:20px;
    }
    .item_listserv .id_tariff,.item_listserv .price_tariff,.item_listserv .edit_tariffbtn{
        margin-bottom:0;
    }
    .item_tariff span,.item_listserv span{
        display:block;
    }
    .item_listserv .hidenRowAgent .hidenItem span{
        white-space:initial;
    }
    .item_tariff input{
        margin-bottom:10px !important;
    }
    .saverdit_ms{
        display:inline-flex;
        height:40px;
    }
    .MyServiceAbout{
        margin:0;
    }
    .top_headmyserv,.hide_servlg{
        display:none;
    }
    .input_editablesrv{
        margin-bottom:10px;
    }
    .input_editablesrv{
        margin-top:10px;
    }
}
@media only screen and (max-width:768.98px){
    .mainRowProPrice2{
        height:unset;
    }
    #tariff_formID{
        display:flex;
        width:100% !important;
        flex-direction:row;
        flex-wrap:wrap;
    }
    
}