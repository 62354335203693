.youareall{
  margin-top: 70px;
}
.tickMob{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.myBoldFont.tickMob::before{
  display:none;
}
.myBoldFont svg{
  margin-inline-end:8px;
}
.myBoldFont.tickMob svg{
  margin-bottom:10px;
  margin-inline-end:0;
}