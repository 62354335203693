.test{
    color:black;
    background-color:brown;
}
.TopMainRowOne{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:10px;
    margin-inline-end:-22px;
}
.MessageText1Icon{
    cursor:pointer;
}
.TopMainRowOne .btn{
    height:35px;
    border-radius:9px;
}
.TopMainRowOne .btn-dark:hover{
    color:#fff;
    background-color:#dbdddf !important;
    border-color:#e3e7ed45;
}
.PboxSecondRowAgCus{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.PboxSecondRowAgCus h2{
    color:black !important;
    font-size:32px !important;
}
.PboxSecondRowAgCus h4,.PboxSecondRow h4{
    color:black !important;
    font-size:17px !important;
}
.PboxSecondRow p{
    color:black !important;
    font-size:14px !important;
}
.Pbox_headagentCus{
    display:flex;
    justify-content:flex-start;
}
.box_selectpbox{
    padding:0;
    width:calc(100% - 105px) !important;
}
.box_selectpbox select:lang(fa){
    border-radius:0 7px 7px 0;
    height:35px !important
}
.box_selectpbox select:lang(en){
    border-radius:7px 0 0 7px;
    height:35px !important
}
.Pbox_headagentCus h4{
    font-size:18px !important;
    padding-top:10px;
}
.FilterBtn{
    border-color:transparent;
    background-color:transparent;
    padding:0px;
    margin:0px;
}
.FilterIconAgent{
    margin-top:-8px;
}
.mainContent .mainRowCus{
    height:380px;
}
.list_cuslaw{
    margin:10px 0 30px 0;
}
.MessageText1Icon{
    cursor:pointer;
}
.list_cuslaw strong{
    color:black;
    font-size:20px !important;
    font-weight:500 !important;
}
.top_listcuslaw .TopMainRowTwo>div{
    padding:0 5px;
    display:flex;
    justify-content:flex-start;
    position:relative;
}
.itemBox2{
    display:flex;
    box-shadow:hsl(0deg 0% 80% / 22%) 0 0 16px;
    background-color:rgb(255, 255, 255);
    border-radius:16px;
    flex-wrap:wrap;
    align-items:center;
    padding:10px 0;
    margin-bottom:10px; 
    position:relative;
    transition:all 0s ease-in-out;
}
.itemBox2>svg,.AddNew_Cus>svg{
    display:none;
}
.itemBox2 p{
    color:#313131 !important;
    margin-bottom:0rem;
    font-size:12px !important;
}
.itemBox2 button{
    font-size:12px !important;
}
.agentCusRow{
    display:flex;
    padding:0 10px;
    align-items:center;
    position:relative;
}
.hidenRowAgent{
    display:none;
    flex-wrap:wrap;
    justify-content:center;
    padding:0 10px 10px;
    width:100%;
    margin:0;
    margin-inline-start:0;
    -webkit-animation:fadeInScale .5s ease-in-out;
	-moz-animation:fadeInScale .5s ease-in-out;
	animation:fadeInScale .5s ease-in-out;
}
@keyframes fadeInScale{
  0% {
  	transform:scale(.9);
  	opacity:0;
  } 
  100% {
  	transform:scale(1);
  	opacity:1;
  }
}
.hidenItemRow{
    display:flex;
    flex-wrap:wrap;
    position:relative;
    width:100%;
}
.hidenItemRow>svg{
    display:none;
}
.hidenRowAgent .hidenItem{
   display:flex;
   align-items:center;
   margin-inline-start:0;
   margin-bottom:7px;
   flex-wrap:wrap;
}
.linebetitem{
    padding:0;
    height:1px;
    background-color:#E3E3E3;
}
.itemBox2 .hidenRowAgent .hidenItem:first-child{
    margin-bottom:0;
}
.id_agentCus,.CusPic2,.count_agentCus,.view_infocus,.add_LawCus,.mail_agentCus,.created_agentCus,.de_active_agentCus,.lawyer_agentCus,.phone_agentCus{
    padding:0 5px;
    display:flex;
    align-items:center;
    position:relative;
}
.phone_agentCus{
    direction:ltr;
}
.id_agentCus{
    padding-inline-start:8px;
    display:flex;
    align-items:flex-end;
}
.notif_daysWaiting{
    position:absolute;
    top:-5px;
}
.notif_daysWaiting:lang(en){
    right:-5px;
}
.notif_daysWaiting:lang(fa){
    left:-5px;
}
.lawyer_agentCus select,.lawyer_agentCusm select{
    margin:0;
    padding:0;
    border:none !important;
    max-width:135px;
}
.de_active_agentCus button{
    width:88px;
    border-radius:5px;
    height:32px;
}
.DeactiveInfoCircle{
    position:absolute;
    top:-10px;
}
.DeactiveInfoCircle:lang(en){
   left:0;
}
.DeactiveInfoCircle:lang(fa){
    right:0;
}
.lawyer_agentCus svg{
    position:absolute;
    top:calc(50% - 9px);
}
.lawyer_agentCus svg:lang(en){
    left:-11px;
}
.lawyer_agentCus svg:lang(fa){
    right:-11px;
}
.CusPic2{
    justify-content:flex-start;
    flex-wrap:wrap;
}
.img_agentCus{
    margin-inline-end:10px;
}
.CusPic2 img{
    width:50px;
    height:50px;
    border-radius:50%;
}
.CusPic2:lang(en){
    direction:ltr;
}
.CusPic2:lang(fa){
    direction:rtl;
}
.Send_Message{
    position:absolute;
    cursor:pointer;
}
.Send_Message:lang(en){
    right:10px;
}
.Send_Message:lang(fa){
    left:10px;
}
input[type="checkbox"]{
    filter:none;
    accent-color:transparent;
}
.phone_agentCus{
    direction:ltr;
}
.MyAgentRowClick{
    display:flex;
    align-items:center;
    padding:0;
    cursor:pointer !important;
    border-color:transparent !important;
    background-color:transparent !important;
    flex-wrap:wrap;
}
.itemBox2:hover{
    transform:scale(1.009);
    z-index:1;
}
.itemBox2:hover .ChoosBox{
    z-index:9999999;
}
.id_agentCusm{
    justify-content:flex-start;
    padding:0;
}
.itemBox2 .hidenRowAgent .hidenItem span{
    color:#666 !important;
    font-size:14px;
    padding-inline-end:10px;
    white-space:nowrap;
}
.Pbox{
    position:absolute;
    margin:0;
    width:50%;
    left:25%;
    top:calc(30% - 125px);
    min-height:250px;
    border-radius:31px;
    background:#fff;
    padding:20px 30px;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
}
.Pbox .overheigthpbox{
    height:400px;
    overflow-y:auto;
    padding:0 10px;
}
.overheigthpbox .box_selectpbox select{
    height:45px !important;
}
.Pbox_agentCus .PboxForthRowSendMail .btn{
   border:1px solid black;
   border-radius:9px;
   width:130px;
   margin:0 5px;
}
.topMain{
    display:flex;
    flex-direction:column;
}
.TopMainRowTwo{
    display:flex;
    color:black;
    padding:0px 2px;
}
.TopMainRowTwo p{
    color:#313131cc !important;
    margin-bottom:0px !important;
    font-size:12px;
}
.CustomerLine{
    color:#3131318a;
    background-color:#3131318a;
    margin:10px 0 0 0 !important;
}
.mainRowCus{
    height:453px;
    overflow-y:auto;
    scrollbar-color:transparent;
    box-shadow:hsl(0deg 0% 80% / 0%) 0 0 16px;
    background-color:rgba(255, 255, 255, 0);
    border-radius:20px;
    padding:20px 7px;
}
.active{
    background-color:#E2F4EC;
    border-color:transparent;
    color:#4A9D77 !important;
    border-radius:8px;
}
.Pending {
    background-color:#cacaca;
    border-color:transparent;
    color:#5f5f5f !important;
    border-radius:8px;
}
.deactive{
    background-color:#FFEDEF;
    border-color:transparent;
    color:#E95060 !important;
    border-radius:8px;
}
.ChoosBox{
    position:absolute;
    background-color:white;
    color:black;
    width:100%;
    display:none;
    align-items:flex-start;
    flex-direction:column;
    top:33px;
    left:0px;
    box-shadow:hsl(0deg 0% 2%) 0 0 10px;
    border-radius:4px;
    z-index:9999;
    padding:10px;
}
.ChoosBox a.disabled{
    pointer-events:none;
    color:#ccc;
}
#DownArrowID{
    display:none;
}
#DownArrowCustomer{
    display:none;
}
#DownArrowCreated{
    display:none;
}
.VisaBox{
    position:absolute;
    background-color:white;
    box-shadow:hsl(0deg 0% 2%) 0 0 10px;
    color:black;
    width:270px;
    display:none;
    align-items:center;
    flex-direction:column;
    top:-25px;
    border-radius:4px;
    padding:2px 10px;
    font-size:12px !important;
    z-index:99;
}
.VisaBox:lang(en){
    left:15px;
}
.VisaBox:lang(fa){
    right:15px;
}
.FilterStatusBox{
    position:absolute;
    z-index:99;
    background-color:white;
    color:black;
    display:none;
    flex-direction:column;
    top:16px;
    box-shadow:hsl(0deg 0% 2% / 22%) 0 0 10px;
    border-radius:4px;
    padding:15px 10px;
    font-size:12px !important;
    justify-content:space-between;
    align-items:flex-start;
    min-width:max-content;
    justify-content:flex-start;
}
.FilterStatusBox:lang(en){
    left:65px;
}
.FilterStatusBox:lang(fa){
    right:65px;
}
.FilterStatusBox label{
    color:#313131 !important;
    font-size:9px;
    padding-inline-start:5px;
    margin:0;
}
.FilterStatusBox label .deactiveCount,.FilterStatusBox label .activeCount{
    margin-inline-start:5px;
    display:inline-flex;
}
.checkBoxAndLabel{
    margin-bottom:5px;
}
.FilterIcon{
    display:flex;
    cursor:pointer;
}
.activeCount{
    color:rgb(6, 231, 6);
    display:contents;
}
.deactiveCount{
    color:rgb(248, 51, 51);
    display:contents;
}
/* Popup style */
.popup-box{
    position:fixed;
    background:#00000070;
    width:100%;
    height:100vh;
    top:0;
    left:0;
    z-index:999999;
}
.close-icon{
    cursor:pointer;
    position:absolute;
    top:25px;
    background:#D0021B;
    width:25px;
    height:25px;
    border-radius:50%;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:50;
    font-family:none;
}
.close-icon span:lang(en){
    position:absolute;
    top:calc(50% - 24px);
    left:calc(50% - 8px);
    font-size:30px;
}
.close-icon span:lang(fa){
    position:absolute;
    top:calc(50% - 25px);
    left:calc(50% - 10px);
    font-size:30px;
  }
.close-icon:lang(en){
    right:25px;
}
.close-icon:lang(fa){
    left:25px;
}
.PboxFirstRow,.PboxSecondRow,.PboxThirdRow{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    padding-top:10px;
}
.PboxThirdRow p{
    color:#313131 !important;
    padding:10px 0px;
}
.PboxForthRow{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-evenly;
    align-items:center;
    padding:0px 40px;
}
.PboxForthRow .btn{
    margin:5px 0;
}
.PboxForthRow button{
    color:#313131;
}
.PboxForthRow .btn-success{
    background-color:#14D32C;
    border-color:#14D32C;
}
.PboxForthRow .btn-success:hover{
    background-color:#3ed151;
    border-color:#3ed151;
}
.PboxForthRow .btn-danger{
    background-color:#D0021B;
    border-color:#D0021B;
}
.PboxForthRow .btn-light{
    border-color:#313131;
    color:#313131;
}
.PboxForthRow2{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:flex-start;
    padding:0;
}
.PboxForthRow2 label{
    color:#313131 !important;
}
.popup-box .PboxForthRow2 input:lang(en),.popup-box .PboxForthRow2 button:lang(fa),.box_selectpbox .css-jxppsg-control:lang(en),.box_selectpbox .css-nqaof0-control:lang(en){
    border-radius:7px 0 0 7px !important;
}
.popup-box .PboxForthRow2 input:lang(fa),.popup-box .PboxForthRow2 button:lang(en),.box_selectpbox .css-jxppsg-control:lang(fa),.box_selectpbox .css-nqaof0-control:lang(fa){
    border-radius:0 7px 7px 0 !important;
}
.TopMainRowTwo a{
    cursor:pointer;
}
.box2{
    background-color:rgb(215 215 215);
    border-radius:5px;
    font-family:sans-serif;
    text-align:center;
    line-height:1;
    -webkit-backdrop-filter:blur(20px);
    backdrop-filter:blur(20px);
    height:100%;
    border-radius:10px;
    padding:3%;
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:space-between;
    align-items:center;
}
.box2 img{
    width:10%;
}
.box2Left{
    display:flex;
}
.CusCodeBox2{
    padding:27px;
}
.DownloadFormBox2{
    padding:20px;
    background:#836565;
    border-radius:8px;
    cursor:pointer;
}
.DocumentDownloadBox2{
    margin-inline-start:10px;
}
.MyMainContentBox2{
    display:flex;
    padding-top:25px;
}
.LawyerBoxInfoTitr{
    color:black !important;
    font-size:18px !important;
    padding:15px;
    background:#f1efef;
}
.MyMainContentBox2Warp{
    display:flex;
    flex-wrap:wrap;
}
.MyMainContentBox2Warp label{
    color:black !important;
}
.MyLabelsCoup{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-bottom:25px;
}
.myAddLawyerCusList{
    border-radius:8px;
    padding:5px;
}
.input-groupLawyerCusServicePopUp{
    position:relative;
    display:flex;
    flex-wrap:wrap;
    align-items:flex-start;
    width:100%;
    justify-content:space-between;
}
.add_servCus,.btn_addLawCus,.btn_infoLawCus{
    height:32px;
    border-radius:5px;
    margin-inline-start:5px;
    width:88px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:14px;
}
.btn_infoLawCus{
    width:auto;
}
.all_btnslawcus{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    margin-top:5px;
}
.btn_addlowyer{
    height:45px;
}
.box_selectpbox .css-nqaof0-control>div,.box_selectpbox .css-jxppsg-control,.box_selectpbox .css-6j8wv5-Input,.box_selectpbox .css-319lph-ValueContainer,.box_selectpbox .css-6j8wv5-Input,.box_selectpbox .css-1pndypt-Input{
    height:43px;
    margin:0 !important;
    padding:0 !important;
}
.box_selectpbox .css-nqaof0-control{
   padding-inline-start:10px;
}
.box_selectpbox .css-nqaof0-control *,.box_selectpbox .css-g1d714-ValueContainer{
   padding:0 auto !important;
}
.all_btnslawcus>*{
   margin:5px 3px;
}
.hidenItem>select{
    min-width:80px;
    margin:0;
}
.itemBox2>svg.arrowshow_list{
    display:block;
    position:absolute;
    top:26px;
    z-index:50;
    cursor:pointer;
}
.itemBox2>svg.arrowshow_list:lang(en){
    right:11px;
}
.itemBox2>svg.arrowshow_list:lang(fa){
    left:11px
}
.pbox_whitscroll>.close-icon:lang(en){
    right:45px;
}
.pbox_whitscroll>.close-icon:lang(fa){
    left:45px;
}
.pbox_whitscroll .PboxForthRow2 button{
    width:200px;
    margin-top:7px;
    display:block;
}
.pbox_whitscroll .PboxForthRow2 button:lang(fa),.pbox_whitscroll .PboxForthRow2 button:lang(en){
    border-radius:7px !important;
}
@media only screen and (min-width:1439.98px){
    .mainContent .mainRowCus{
        height:700px;
    }
}
@media only screen and (max-width:992px) and (max-width:1199.98px){
    .lawyer_agentCus svg{
        display:none;
    }
}
@media only screen and (max-width:991.98px){
    .list_cuslaw strong{
        font-size:16px !important;
    }
    .CustomerLine{
        display:none;
    }
    .hidenItemRow>svg{
        display:inline-block;
        position:absolute;
        top:25px;
        z-index:50;
    }
    .hidenItemRow>svg:lang(en){
        right:0;
    }
    .hidenItemRow>svg:lang(fa){
        left:0;
    }
    .list_cuslaw{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
   .lawyer_agentCus,.Send_Message,.add_servCus,.view_infocus,.add_LawCus,.phone_agentCus,.id_agentCus,.created_agentCus,.TopMainRowTwo,.mail_agentCus,.count_agentCus{
       display:none;
   }
   .all_btnslawcus .add_servCus{
        display:flex;
   }
   .CusPic2{
       flex-direction:row;
   }
   .itemBox2>svg{
       display:block;
       position:absolute;
       top:26px;
       z-index:50;
       cursor:pointer;
   }
   .itemBox2 .hidenRowAgent .hidenItem span{
        white-space:initial;
    }
   .AddNew_Cus>svg{
        display:block;
   }
   .itemBox2>svg:lang(en){
        right:11px;
   }
   .itemBox2>svg:lang(fa){
       left:11px
    }
    .Pbox{
        width:90%;
        padding:30px 20px;
        left:5%;
        top:calc(50% - 125px);
    }
    .AddNew_Cus{
        position:fixed;
        z-index:999;
        bottom:20px;
        width:50px;
        height:50px !important;
        border-radius:50% !important;
        font-size:0 !important;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .AddNew_Cus:lang(en){
        right:20px;
    }
    .AddNew_Cus:lang(fa){
        left:20px;
    }
    .mainContent .mainRowCus{
        height:unset;
        min-height:380px;
    }
}
@media only screen and (max-width:991.98px){
    .close-icon{
        top:-12px
    }
}
@media only screen and (max-width:400.98px){
    .de_active_agentCus button{
        width:75px;
        padding:0;
    }
}