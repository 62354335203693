.MyCusBtn,.MyCusBtnBlack{
    display: flex;
    border:1px solid #313131;
    border-radius: 12px;
    padding: 12px 15px;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.MyCusBtn p {
    color: #313131 !important;
    padding: 0px !important;
    margin-bottom: 0;
    font-size: 16px!important;
}
.MyCusBtnBlack {
    background-color: black;
}
.MyCusBtnBlack p {
    color: #ffffff !important;
    padding: 0px !important;
    margin-bottom: 0;
    font-size: 16px!important;
}
.MyCusBtnLabelProgress,.MyCusBtnLabel,.MyCusCompletedgreenP,.MyCusBtnLabelCompleted{
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    padding:0 5px 5px;
}
.MyCusBtnLabelProgress p,.MyCusBtnLabel p,.MyCusCompletedgreenP p,.MyCusBtnLabelCompleted p{
    padding: 0px !important;
    margin-bottom: 0;
    font-size:14px !important;
}
.MyCusBtnLabel p{
    color: #9B9B9B !important;
}
.MyCusBtnLabelProgress p{
    color: #D3A281 !important;
}
.MyCusCompletedgreenP p,.MyCusCompletedgreenP{
    color: #3EC729 !important;
}
.MyCusProgressP svg,.MyCusCompletedgreenP svg{
    padding-inline-end:5px;
}
.MyCusButtons{
    padding:15px;
}
.Cus2Inputs22 {
    display: flex;
    flex-wrap: wrap;
}
.Step1e1{
    margin-bottom:15px;
}
@media only screen and (max-width: 1199.98px) and (min-width: 992px){
    .MyCusButtons{
        width:50% !important;
        max-width:unset;
    }
}
@media only screen and (max-width: 1199.98px){
    .MyCusButtons{
        padding:8px;
    }
    
}
@media only screen and (max-width: 991.98px){
    .MyCusButtons{
        margin-bottom:10px;
    } 
    .MyCusBtn{
        padding:12px 7px;
    }
}
@media only screen and (max-width: 600.98px){
    .MyCusBtn{
        padding:12px 5px;
    }
    .Step1e1{
        flex-direction:column-reverse;
    }
    .Step1e1 a{
        margin-bottom:10px;
    }
    .MyCusButtons{
        width:100%;
        max-width:280px;
        margin:10px auto;
    }
}