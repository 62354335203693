.cusS1SaveColP5{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 7%;
}
.cusS1SaveColP5 .btn-outline-light{
    color: black !important;
    border-color: #c3c3c3;
    border-radius: 8px;
    height: calc(1.5em + .75rem + 10px) !important;
}
.cusS1SaveColP5 .btn-dark{
    border-radius: 8px;
    height: calc(1.5em + .75rem + 13px) !important;
}
.botCusDraftCol{
    display: flex;
}
.botCusDraftCol .btn-outline-light{
    border-color: transparent;
    color: #D3A281 !important;
}
.botCusDraftCol svg {
    margin-bottom: 5px;
    margin-inline-end: 5px;
}
.GrayListBox p {
    color: black !important;
    padding: 10px !important;
    margin-bottom: 0px !important;
}
.GrayListBox{
    background-color: #EFEFEF;
    padding: 5px;
    margin-bottom: 5px;
    margin-inline-end: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    width: max-content;
    border-radius: 8px;
}
.grayListRow{
    padding: 20px 16px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.inputAndlabelAddA{
    color: blue !important;
    text-decoration: none;
    padding: 20px !important;
}
.inputAndlabelAddA:hover {
    text-decoration: none;
    cursor: pointer;
}
.mainGrayListRows{
    display: flex;
    flex-wrap: wrap;
}