.box_MyCusUploadReactangle{
    padding:0 20px;
    margin:20px 0;
    max-height:190px;
}
.MyCusUploadReactangle{
    display:flex;
    flex-direction:column;
    align-items:center;
    border:#D4D4D4 solid 1px;
    border-radius:18px;
    padding:10px;
    min-height:190px;
}
.MyCusUploadReactangle p{
    color:#313131 !important;
    margin-top:20px;
}
.MyCusUploadGray{
    background-color:#D4D4D4;
    border-radius:12px;
    width:100%;
    height:90px;
    display:flex;
    align-content:center;
    justify-content:center;
    align-items:center;
    position:relative;
}
.MyClickAbleRectForHide{
    position:relative;
    max-height:90px;
    width:100%;
}
.MyCusUploadGrayTrans{
    background-color:#000000;
    border-radius:12px;
    height:90px;
    width:100%;
    align-content:center;
    justify-content:center;
    align-items:center;
    top:0px;
    position:absolute;
    opacity:0.8;
    display:none;
}
.MyCusUploadReactangle svg{
    margin:0 3px !important;
}
.MyCusInputFileUpload{
    color:transparent;
    position:absolute;
    width:100%;
    border-radius:12px;
    height:90px;
    font-size:0 !important;
    height:90px;
    font-size:0 !important;
    top:0;
    left:0;
    right:0;
    border:none;
    opacity:0;
    cursor:pointer;
}
.MyCusImageUploadPreview{
    width:100%;
    max-height:90px;
    border-radius:12px;
    height:100px;
    display:none;
}
.MyClickAbleRectForHide:hover .MyCusUploadGrayTrans{
    display:flex;
}
.MyRedTrash{
    cursor:pointer;
}
.MyFileUploadRow{
    display:flex;
    justify-content:flex-start;
    flex-wrap:wrap;
}
.MyGrayBoxTrans svg{
    margin:0 4px;
}
@media only screen and (min-width:1400px){
    .box_MyCusUploadReactangle{
        width:25%;
    }
}
@media only screen and (max-width:991.98px){
    .MyFileUploadRow{
        justify-content:space-around;
    }
}
@media only screen and (max-width:650.98px){
    .box_MyCusUploadReactangle{
        width:100%;
        max-width:300px;
    }
}